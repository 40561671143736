import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Accordion, Nav, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import ic_dropdown from "../../assets/images/chat/ic_dropdown.svg";
import ic_come_back from "../../assets/images/chat/ic_come_back.svg";
import ic_on_call from "../../assets/images/chat/ic_on_call.svg";
import ic_minus_red from "../../assets/images/chat/ic_minus_red.svg";
import ic_offline from "../../assets/images/chat/ic_offline.svg";
import profile_img from "../../assets/images/profile_img.svg";
import ic_video_call from "../../assets/images/chat/ic_video_call.svg";
import bx_phone_call from "../../assets/images/chat/bx_phone_call.svg";
import moment from "moment";
import { BASE_URL_CHAT_Image } from "../../api/ApiConfig";
import CallHystoryComp from "./CallHystoryComp";
import Loader from "../Loader";

const Call = () => {
  const {
    callsLoading,
    sidebar,
    callHystory,
    setcallsLoading,
    socket,
    callPage,
    setCallLoadMore,
  } = useContext(AppContext);
  const [history, setHistory] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    console.log(callPage);
    socket && socket.emit("call-history", { page: callPage });
  }, [socket, callPage]);
  useEffect(() => {
    socket &&
      socket.on("call-history", (data) => {
        //  setcallsLoading(false)

        if (data.length == 0) {
          setCallLoadMore(false);
          setHistory(history);
        } else {
          console.log(history);
          setHistory([...history, ...data]);
        }
      });
  }, [socket]);

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="main-chat" id="call_main_wrap">
        <Tab.Container id="left-tabs-example">
          <div className="row justify-content-around">
            {callsLoading ? (
              <Loader />
            ) : (
              <>
                <div className="col-lg-3 left-chat-section p-0">
                  <div className="left-heading-chat">
                    <div className="left-head">
                      <label style={{ color: "var(--black_111111_LightMode)" }}>
                        Calls
                      </label>
                    </div>
                  </div>
                  <div className="chat-lineseparater"></div>
                  <div className="call_list_Left">
                    <div className="inpute_top">
                      <input
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                    <div className="list-main-wrapper">
                      {
                        // getUniqueListBy(callHystory, "Team_Channel_Name").
                        history.length &&
                          history
                            .filter((call) => {
                              return search.length
                                ? (call.incomingLastName &&
                                    call.incomingLastName
                                      .toLowerCase()
                                      .includes(search.toLowerCase())) ||
                                    (call.incomingFirstName &&
                                      call.incomingFirstName
                                        .toLowerCase()
                                        .includes(search.toLowerCase())) ||
                                    (call.FirstName &&
                                      call.FirstName.toLowerCase().includes(
                                        search.toLowerCase()
                                      )) ||
                                    (call.LastName &&
                                      call.LastName.toLowerCase().includes(
                                        search.toLowerCase()
                                      )) ||
                                    call.channel_name
                                      .toLowerCase()
                                      .includes(search.toLowerCase())
                                : call;
                            })
                            .map((call, index) => {
                              return (
                                <div
                                  className="call-single-left"
                                  key={"call" + index}
                                >
                                  <div className="list-main">
                                    <div className="img-call-wrap">
                                      <img
                                        src={
                                          call.call_type === "Outgoing"
                                            ? BASE_URL_CHAT_Image +
                                              call.PhotoPath
                                            : BASE_URL_CHAT_Image +
                                              call.incomingPhotoPath
                                        }
                                        alt=""
                                      />
                                      <span>
                                        <img src={ic_on_call} alt="" />
                                      </span>
                                    </div>
                                    <p>
                                      {call.call_type === "Outgoing"
                                        ? call.FirstName == null
                                          ? "Group Call"
                                          : call.FirstName + " " + call.LastName
                                        : call.incomingFirstName +
                                          " " +
                                          call.incomingLastName}{" "}
                                      {"(" + call.Team_Channel_Name + ")"}
                                    </p>
                                  </div>
                                  <div className="Call_vedio">
                                    <Link
                                      to="#"
                                      className="right-icon-chat"
                                      onClick={() => {
                                        socket &&
                                          socket.emit("call-initiate", {
                                            schannel_id: call.schannel_id,
                                          });
                                      }}
                                    >
                                      <img src={bx_phone_call} alt="" />
                                    </Link>
                                    {/* <Link to="#" className="right-icon-chat">
                                                            <img src={ic_video_call} alt="" />
                                                        </Link> */}
                                  </div>
                                </div>
                              );
                            })
                      }
                    </div>
                  </div>
                </div>
                <div className="col-lg-9 right-chat-section">
                  <div className="right-heading-chat">
                    <div className="right-head">
                      <label style={{ color: "var(--black_111111_LightMode)" }}>
                        Call History
                      </label>
                    </div>
                  </div>
                  {/* <div className="chat-lineseparater"></div> */}
                  <div className="History-tabs-main">
                    <Tabs
                      defaultActiveKey="All"
                      id="uncontrolled-tab-example"
                      className="mb-3"
                    >
                      <Tab eventKey="All" title="All">
                        <CallHystoryComp callHistory={history} />
                      </Tab>
                      <Tab eventKey="Out Going" title="Outgoing">
                        <div className="callerlistWrapper">
                          <CallHystoryComp
                            callHistory={history.filter(
                              (call) => call.call_type === "Outgoing"
                            )}
                          />
                        </div>
                      </Tab>
                      <Tab eventKey="profile" title="Missed">
                        <CallHystoryComp
                          callHistory={history.filter(
                            (call) => call.call_status === "Missed"
                          )}
                          missed={true}
                        />
                      </Tab>
                      <Tab eventKey="contact" title="Incoming">
                        <CallHystoryComp
                          callHistory={history.filter(
                            (call) => call.call_type === "Incoming"
                          )}
                        />
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </>
            )}
          </div>
        </Tab.Container>
      </div>
    </div>
  );
};

export default Call;
