import React, { useState, useContext, useEffect, useRef } from "react";
import { AppContext } from "../../../context/AppContext";
import { Link } from "react-router-dom";
import { Form, Modal } from "react-bootstrap";
import deleteicon from "../../../assets/images/DelectAcBlc.svg";
import editicon from "../../../assets/images/EditacBlc.svg";
import {
  DeleteCallWithErrorResponseWithTokenQuery,
  PostCallWithErrorResponse,
  putWithAuthCall,
  simpleGetCallWithToken,
} from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import { ToastMsg } from "../../TostMsg";
import Loader from "../../Loader";
import searchicon from "../../../assets/images/ic_search_dark.svg";
import ic_search_dark from "../../../assets/images/ic_search_dark.svg";
import NoData from "../../../sharedComponent/NoData";
import { parseInt } from "lodash";
import Pagination from "../../../sharedComponent/Pagination";

const ManageLoc = () => {
  const { sidebar, setSidebar, Dark, setDark, userData, permissions } =
    useContext(AppContext);

  const [locationList, setLocationList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectTimeZoneValue, setSelectTimeZoneValue] = useState("");
  const [error, setError] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(25);

  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);

  const locationId = useRef();
  const userName = useRef();

  const [addlocationValues, setAddLocationValues] = useState({
    LocationName: "",
    Country: "",
    EmailAlias: "",
    Lat: "",
    Long: "",
  });

  const [show, setShow] = useState(false);

  useEffect(() => {
    getLopcationListData();
    getLopcationList();
  }, []);

  const handleClose = (e) => {
    e?.preventDefault();
    setAddLocationValues("");
    setValidationErrors("");
    validateLatLong("");
    setShow(false);
  };
  const handleShow = () => {
    setSelectTimeZoneValue("");
    setShow(true);
  };

  const [showed, setShowed] = useState(false);
  const handleClosed = () => {
    setAddLocationValues("");
    setShowed(false);
  };
  const handleShowed = (item) => {
    setShowed(true);
    if (item) {
      locationId.current = item?.LocationId;
      setAddLocationValues(item);
      setSelectTimeZoneValue(item?.TimeZoneValue);
    }
    console.log("location item-->", locationId.current);
  };

  const [showdel, setShowdel] = useState(false);
  const handleClosedel = () => {
    setShowdel(false);
  };
  const handleShowdel = (item) => {
    setShowdel(true);
    locationId.current = item.LocationId;
    userName.current = item?.LocationName;
    console.log("item for location delete-->", item?.LocationId);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddLocationValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const onTimeZoneChange = (e) => {
    const selectedValue = e.target.value;
    const selectedItem = timeZoneOptions.find(
      (item) => item.value === selectedValue
    );
    console.log("selectedValue--->", selectedItem);
    setSelectTimeZoneValue(e.target.value);
  };

  const getLopcationListData = () => {
    setLoading(true);
    simpleGetCallWithToken(ApiConfig.GET_LOCATION)
      .then((res) => {
        setLoading(false);
        console.log("get Location List---->", res);
        if (res.Success === true) {
          setLocationList(res.Data);
          // setSearchResult(res?.Data)

          console.log("get Location Listttt---->", res.Data);
        } else {
          console.log("get Location List Error");
        }
      })
      .catch((err) => {
        console.log("get Location List Error", err);
      });
  };

  const getLopcationList = () => {
    // setLoading(true)
    simpleGetCallWithToken(ApiConfig.GET_LOCATION)
      .then((res) => {
        // setLoading(false)
        console.log("get Location List---->", res);
        if (res.Success === true) {
          setLocationList(res.Data);
          // setSearchResult(res?.Data)
        } else {
          console.log("get Location List Error");
        }
      })
      .catch((err) => {
        console.log("get Location List Error", err);
      });
  };

  // useEffect(() => {
  //   const filteredDepartmentList = locationList.filter((item) =>
  //     item.LocationName.toLowerCase().includes(searchValue.toLowerCase())
  //   );
  //   setSearchResult(filteredDepartmentList)
  // }, [searchValue, locationList])

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateLatLong = (value) => {
    let latLongRegex = /^-?\d+(\.\d{1,10})$/;
    return latLongRegex.test(value);
  };

  const [validationErrors, setValidationErrors] = useState({});

  const onAddOrUpdateLocation = (e) => {
    e.preventDefault();
    setLoading(true);

    const { LocationName, Country, EmailAlias, Lat, Long } = addlocationValues;
    const errors = {};

    // Validation checks
    if (!LocationName) errors.LocationName = "Please enter location name!";
    if (!Country) errors.Country = "Please enter country name!";
    if (!EmailAlias) {
      errors.EmailAlias = "Please enter email alias!";
    } else if (!validateEmail(EmailAlias)) {
      errors.EmailAlias = "Please enter a valid email address!";
    }
    if (!Lat) {
      errors.Lat = "Please enter latitude!";
    } else if (!validateLatLong(Lat)) {
      errors.Lat =
        "Please enter a valid latitude (up to 10 digits after the decimal)!";
    }
    if (!Long) {
      errors.Long = "Please enter longitude!";
    } else if (!validateLatLong(Long)) {
      errors.Long =
        "Please enter a valid longitude (up to 10 digits after the decimal)!";
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      setLoading(false);
    } else {
      const body = {
        LocationName: addlocationValues?.LocationName,
        Country: addlocationValues?.Country,
        EmailAlias: addlocationValues?.EmailAlias,
        Lat: Number(addlocationValues?.Lat),
        Long: Number(addlocationValues?.Long),
        TimeZone: 0,
        TimeZoneValue: selectTimeZoneValue ? selectTimeZoneValue : null,
      };
      PostCallWithErrorResponse(
        ApiConfig.ADD_UPDATE_LOCATION,
        JSON.stringify(body)
      )
        .then((res) => {
          console.log("Add Location Response-->", res);
          if (res.json.Success === true) {
            console.log("Add Location Response-->", res);
            ToastMsg("success", res.json.Message);
            setShow(false);
            setLoading(false);
            setValidationErrors("");
            validateLatLong("");
            setAddLocationValues("");
            setSelectTimeZoneValue("");
            getLopcationListData();
          } else {
            ToastMsg("error", res.json.Message);
            setShow(false);
            setLoading(false);
            setAddLocationValues("");
            setSelectTimeZoneValue("");
          }
        })
        .catch((err) => {
          console.log("Add Location Error-->", err);
          setLoading(false);
        });
    }

    // }
  };

  const onUpdateData = (e) => {
    e.preventDefault();

    let id = locationId.current;

    const body = {
      LocationId: parseInt(id),
      LocationName: addlocationValues?.LocationName,
      Country: addlocationValues?.Country,
      EmailAlias: addlocationValues?.EmailAlias,
      Lat: Number(addlocationValues?.Lat),
      Long: Number(addlocationValues?.Long),
      TimeZone: 0,
      TimeZoneValue: selectTimeZoneValue ? selectTimeZoneValue : "",
    };
    PostCallWithErrorResponse(
      ApiConfig.ADD_UPDATE_LOCATION,
      JSON.stringify(body)
    )
      .then((res) => {
        if (res.json.Success === true) {
          console.log("Add Location Response-->", res);
          ToastMsg("success", res.json.Message);
          setShowed(false);
          setLoading(false);
          getLopcationListData();
          setAddLocationValues("");
          setSelectTimeZoneValue("");
        } else {
          ToastMsg("error", res.json.Message);
          setShowed(false);
          setLoading(false);
          setAddLocationValues("");
          setSelectTimeZoneValue("");
        }
      })
      .catch((err) => {
        console.log("Add Location Error-->", err);
        setLoading(false);
        setShowed(false);
      });
  };

  const onDeleteLocation = (e) => {
    e.preventDefault();
    // setLoading(true)
    DeleteCallWithErrorResponseWithTokenQuery(
      ApiConfig.DELETE_LOCATION.replace("{id}", locationId.current)
    )
      .then((res) => {
        if (res.json.Success === true) {
          ToastMsg("success", res.json.Message);
          setShowdel(false);
          // setLoading(false)
          getLopcationList();
        } else {
          ToastMsg("error", res.json.Message);
          setShowdel(false);
        }
      })
      .catch((err) => {
        console.log("Delete Location Error-->", err);
      });
  };

  const timeZoneOptions = [
    { value: "-12:00", label: "(GMT-12:00) International Date Line West" },
    { value: "-11:00", label: "(GMT-11:00) Midway Island, Samoa" },
    { value: "-10:00", label: "(GMT-10:00) Hawaii" },
    { value: "-09:00", label: "(GMT-09:00) Alaska" },
    {
      value: "-08:00",
      label: "(GMT-08:00) Pacific Time (US & Canada); Tijuana",
    },
    {
      value: "-07:00",
      label:
        "(GMT-07:00) Chihuahua, La Paz, Mazatlan, Mountain Time (US & Canada)",
    },
    {
      value: "-06:00",
      label:
        "(GMT-06:00) Central America, Central Time (US & Canada), Guadalajara",
    },
    {
      value: "-05:00",
      label: "(GMT-05:00) Bogota, Lima, Quito, Eastern Time (US & Canada)",
    },
    {
      value: "-04:00",
      label: "(GMT-04:00) Atlantic Time (Canada), Caracas, La Paz, Santiago",
    },
    {
      value: "-03:00",
      label:
        "(GMT-03:00) Newfoundland, Brasilia, Buenos Aires, Georgetown, Greenland",
    },
    { value: "-02:00", label: "(GMT-02:00) Mid-Atlantic" },
    { value: "-01:00", label: "(GMT-01:00) Azores, Cape Verde Island" },
    {
      value: "0",
      label:
        "(GMT) Casablance, Monrovia, Greenwich Mean Time: Edinburgh, London",
    },
    {
      value: "+01:00",
      label:
        "(GMT+01:00) Amsterdam, Berlin, Rome, Vienna, Paris, West Central Africa",
    },
    {
      value: "+02:00",
      label: "(GMT+02:00) Athens, Cairo, Jerusalem, Istanbul",
    },
    { value: "+03:00", label: "(GMT+03:00) Baghdad, Kuwait, Moscow, Tehran" },
    {
      value: "+04:00",
      label: "(GMT+04:00) Abu Dhabi, Muscat, Baku, Tbilisi, Yerevan",
    },
    { value: "+04:30", label: "(GMT+04:30) Kabul" },
    {
      value: "+05:00",
      label: "(GMT+05:00) Ekaterinburg, Islamabad, Karachi, Tashkent",
    },
    {
      value: "+05:30",
      label: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    },
    { value: "+05:45", label: "(GMT+05:45) Kathmandu" },
    {
      value: "+06:00",
      label: "(GMT+06:00) Almaty, Novosibirsk, Astana, Dhaka",
    },
    { value: "+06:30", label: "(GMT+06:30) Rangoon" },
    {
      value: "+07:00",
      label: "(GMT+07:00) Bangkok, Hanoi, Jakarta, Krasnoyarsk",
    },
    {
      value: "+08:00",
      label: "(GMT+08:00) Beijing, Taipei, Hong Kong, Ulaan Bataar, Singapore",
    },
    { value: "+09:00", label: "(GMT+09:00) Osaka, Tokyo, Seoul" },
    { value: "+09:30", label: "(GMT+09:30) Adelaide, Darwin" },
    {
      value: "+10:00",
      label: "(GMT+10:00) Brisbane, Sydney, Guan, Vladivostok, Melbourne",
    },
    {
      value: "+11:00",
      label: "(GMT+11:00) Magadan, Solomon Island, New Caledonia",
    },
    {
      value: "+12:00",
      label:
        "(GMT+12:00) Auckland, Wellington, Fiji, Kamchatka, Marshall Island",
    },
    { value: "+13:00", label: "(GMT+13:00) Nuku'alofa" },
  ];

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };
  const searchedDataPageCounts =
    locationList.length &&
    locationList.filter((item) =>
      item.LocationName?.toLowerCase().includes(searchValue)
    );
    function extractTimeOffset(timeString) {
      // Handle both "+" and "-" in the time zone value
      const sign = timeString.includes("-") ? "-" : "+";
      const parts = timeString.split(sign);
    
      if (parts.length > 1) {
        return sign + parts[1].trim();
      }
    
      return "";
    }
    

  const locationData =
    locationList &&
    locationList.slice(startIndex, endIndex).map((location, index) => {
      return (
        <tr className="table-row-custom">
          <td>{index + 1}</td>
          <td>{location?.LocationName}</td>
          <td>{location?.Country}</td>
          <td>{location?.EmailAlias}</td>
          <td>{location?.Lat}</td>
          <td>{location?.Long}</td>
          <td> GMT  {extractTimeOffset(location?.TimeZoneValue)}</td>
          <td>
            <img onClick={() => handleShowed(location)} src={editicon}></img>
            <img onClick={() => handleShowdel(location)} src={deleteicon}></img>
          </td>
        </tr>
      );
    });

  const locationDataSearch =
    locationList &&
    locationList
      ?.filter((team) =>
        team.LocationName?.toLowerCase().includes(searchValue.toLowerCase())
      )
      .slice(startIndex, endIndex)
      .map((location, index) => {
        return (
          <tr className="table-row-custom">
            <td>{startIndex + index + 1}</td>
            <td>{location?.LocationName}</td>
            <td>{location?.Country}</td>
            <td>{location?.EmailAlias}</td>
            <td>{location?.Lat}</td>
            <td>{location?.Long}</td>
            <td> GMT + {extractTimeOffset(location?.TimeZoneValue)}</td>
            <td>
              <img onClick={() => handleShowed(location)} src={editicon}></img>
              <img
                onClick={() => handleShowdel(location)}
                src={deleteicon}
              ></img>
            </td>
          </tr>
        );
      });

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role ">
          <h6 className="mt-2">Organization Info</h6>
        </div>
      </div>
      <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
        <div className="role-table-header accoridance">
          <div className="search-input-wrapper allAcordiance">
            {
              <Link to="/OrgDetails">
                <button className="all-anc-btn-sub-header">
                  Organization Details
                </button>
              </Link>
            }
          </div>
          <div className="entries-wrapper myAccordiance">
            {
              <Link to="/ManageDept">
                <button className="my-anc-btn-sub-header">
                  Manage Department
                </button>
              </Link>
            }
          </div>
          <div className="entries-wrapper myAccordiance">
            {
              <Link to="/ManageDesig">
                <button className="my-anc-btn-sub-header">
                  Manage Designation
                </button>
              </Link>
            }
          </div>
          <div className="entries-wrapper myAccordiance">
            {
              <Link to="/ManageLoc">
                <button className="my-anc-btn-sub-header active">
                  Manage Locations
                </button>
              </Link>
            }
          </div>
        </div>
      </div>
      <div>
        <div className="d-flex  justify-content-between ">
          <div className="search-input-wrapper">
            <input
              type="text"
              placeholder="Search Location"
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
            {Dark === "lightMode" ? (
              <img src={searchicon} alt="" />
            ) : (
              <img src={ic_search_dark} alt="" />
            )}
            <select
              className="form-select select-drop-icon ml-5"
              aria-label="Default select example"
              onChange={(e) => {
                setUsersPerPage(e.target.value);
                setCurrentPage(0);
              }}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="25" selected>
                25
              </option>
            </select>
          </div>
          <div className="d-flex justify-content-end my-3 ">
            <button className="allocateionBtn mt-2" onClick={handleShow}>
              + Add Location
            </button>
          </div>
        </div>

        {/* <div className="d-flex justify-content-end my-3">
          <button className="allocateionBtn" onClick={handleShow}>
            Add New Record
          </button>
        </div> */}
        <div
          className="table-wrapper custom-table-width"
          id="scrol-tblNtable-wrapper-af"
        >
          {loading ? (
            <Loader />
          ) : locationData && locationData.length > 0 ? (
            <table>
              <thead>
                <tr className="firstTabelRow">
                  <th>Sr. no.</th>
                  <th>Location Name</th>
                  <th>Country</th>
                  <th>Email Alias</th>
                  <th>Latitude</th>
                  <th>Logitude</th>
                  <th>Time Zone</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {searchValue === "" ? locationData : locationDataSearch}
              </tbody>
            </table>
          ) : (
            <NoData />
          )}
          {!loading && searchedDataPageCounts.length > usersPerPage && (
            <Pagination
              data={locationList}
              pageChangeHandler={pageChangeHandler}
              usersPerPage={usersPerPage}
              currentPage={currentPage}
              searchValue={searchValue}
              searchedDataPageCounts={searchedDataPageCounts}
            />
          )}
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          //dialogClassName="custom-modal"
          size="lg"
          dialogClassName="modal-90w"
          centered
        >
          <Modal.Header closeButton className="custom-close-btn">
            <Modal.Title className="custom-modal-title">
              Add New Location
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="locationName" className="mb-3">
                <Form.Label>Location Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Location Name"
                  name="LocationName"
                  value={addlocationValues.LocationName}
                  onChange={handleInputChange}
                  isInvalid={!!validationErrors.LocationName}
                />
                <Form.Control.Feedback type="invalid">
                  {validationErrors.LocationName}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="country" className="mb-3">
                <Form.Label>Country</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Country"
                  name="Country"
                  value={addlocationValues.Country}
                  onChange={handleInputChange}
                  isInvalid={!!validationErrors.Country}
                />
                <Form.Control.Feedback type="invalid">
                  {validationErrors.Country}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="emailAlias" className="mb-3">
                <Form.Label>Email Alias</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email Alias"
                  name="EmailAlias"
                  value={addlocationValues.EmailAlias}
                  onChange={handleInputChange}
                  isInvalid={!!validationErrors.EmailAlias}
                />
                <Form.Control.Feedback type="invalid">
                  {validationErrors.EmailAlias}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="latitude" className="mb-3">
                <Form.Label>Latitude</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Latitude"
                  name="Lat"
                  value={addlocationValues.Lat}
                  onChange={handleInputChange}
                  isInvalid={!!validationErrors.Lat}
                />
                <Form.Control.Feedback type="invalid">
                  {validationErrors.Lat}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="longitude" className="mb-3">
                <Form.Label>Longitude</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Longitude"
                  name="Long"
                  value={addlocationValues.Long}
                  onChange={handleInputChange}
                  isInvalid={!!validationErrors.Long}
                />
                <Form.Control.Feedback type="invalid">
                  {validationErrors.Long}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="company" className="mb-3">
                <Form.Label>Time Zone</Form.Label>
                <Form.Select
                  name="TimeZone"
                  value={selectTimeZoneValue || ""}
                  onChange={onTimeZoneChange}
                >
                  <option>Select</option>
                  {timeZoneOptions?.map((item) => (
                    <option key={item} value={item.value}>
                      {item?.label}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please Enter Time Zone!
                </Form.Control.Feedback>
              </Form.Group>

              <hr />
              <div className="d-flex justify-content-end">
                <button
                  className="mx-2 allocateionBtnwhite"
                  onClick={handleClose}
                >
                  cancel
                </button>
                <button
                  className="allocateionBtn"
                  onClick={onAddOrUpdateLocation}
                >
                  save
                </button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
        {console.log("log--->", addlocationValues)}
        <Modal
          show={showed}
          onHide={handleClosed}
          //dialogClassName="custom-modal"
          size="lg"
          dialogClassName="modal-90w"
          centered
        >
          <Modal.Header closeButton className="custom-close-btn">
            <Modal.Title className="custom-modal-title">
              Update Location
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="company" className="mb-3">
                <Form.Label>Location Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Location Name"
                  name="LocationName"
                  value={addlocationValues.LocationName}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Location Name!
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="company" className="mb-3">
                <Form.Label>Country</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Country"
                  name="Country"
                  value={addlocationValues.Country}
                  onChange={handleInputChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Country Name!
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="company" className="mb-3">
                <Form.Label>Email Alias</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Email Alias"
                  name="EmailAlias"
                  value={addlocationValues.EmailAlias}
                  onChange={handleInputChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Email Name!
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="company" className="mb-3">
                <Form.Label>Latitude</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Latitude"
                  name="Lat"
                  value={addlocationValues.Lat}
                  onChange={handleInputChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Latitude!
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="company" className="mb-3">
                <Form.Label>Longitude</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Longitude"
                  name="Long"
                  value={addlocationValues.Long}
                  onChange={handleInputChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Longitude!
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="company" className="mb-3">
                <Form.Label>Time Zone</Form.Label>
                <Form.Select
                  name="TimeZone"
                  value={selectTimeZoneValue || ""}
                  onChange={onTimeZoneChange}
                >
                  <option>Select</option>
                  {timeZoneOptions?.map((item) => (
                    <option key={item} value={item.value}>
                      {item?.label}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please Enter Time Zone!
                </Form.Control.Feedback>
              </Form.Group>
              <hr />
              <div className="d-flex justify-content-end">
                <button
                  className="mx-2 allocateionBtnwhite"
                  onClick={handleClose}
                >
                  cancel
                </button>
                <button className="allocateionBtn" onClick={onUpdateData}>
                  save
                </button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>

        <Modal
          show={showdel}
          onHide={handleClosedel}
          //dialogClassName="custom-modal"
          size="lg"
          dialogClassName="modal-90w"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="delete-title">
              Delete Confirmation
            </Modal.Title>
          </Modal.Header>
          <div class="lineSeperate"></div>
          <Modal.Body className="delete-body ps-4">
            <p className="text-center m-3">
              {" "}
              Are you sure, You want to delete? {userName.current}
            </p>
          </Modal.Body>

          <div className="lineSeperate"></div>
          <div className="d-flex justify-content-end align-items-center mainBtnsSub">
            <button
              className="btn-width cancelBtn mx-3"
              onClick={handleClosedel}
            >
              Cancel
            </button>
            <button className="btn-width saveBtn" onClick={onDeleteLocation}>
              Delete
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
};
export default ManageLoc;
