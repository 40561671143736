// export const BASE_URL = "https://api.wifiattendance.com/";
export const DEVELOPEMENT_URL = "https://18.188.42.141/";
//export const BASE_URL ='http://localhost:44339/'
export const BASE_URL = "http://3.137.74.85:8080/";
// export const BASE_URL = "http://3.137.74.85:8080/";

// export const BASE_URL="http://wifiapi.cloudxperte.com:8080/"

// export const BASE_URL_CHAT = 'http://192.168.1.38:3015'

//export const BASE_URL = "http://wifiapi.cloudxperte.com:8080/"; // zam zam

export const BASE_URL_CHAT = "https://chat.wifiattendance.com:3015";

//export const BASE_URL_CHAT = "https://zamzam.cloudxperte.com:3015";
//export const BASE_URL_CHAT_Image = "https://zamzam.cloudxperte.com:3015";
export const BASE_URL_CHAT_Image = "https://chat.wifiattendance.com";

export const BASE_URL_Offoce_caller =
  "https://cloudxperte.crm-api.wifiattendance.com:3016";

export const PROFILR_iMG_PATH = "assets/uploads/";

const LOGIN_PATH = "api/authentication/";
const USER_PATH = "api/users/";
const ATTENDANCE_PATH = "api/attendance/";
const TASK_PATH = "api/taskmanagement/";
const TIME_SHEET = "api/timesheet/";
const PRJECT_PATH = "api/Project/";
const DASHBOARD_PATH = "api/dashboard/";
const ORGANIZATION_PATH = "api/organization/";
const PROFILE_PATH = "api/myprofile/";
const PROJECT_PATH = "api/project/";
const ROLE_PATH = "api/roles/";
const COMPLIANCE = "compliance/";
const HOLIDAY_PATH = "api/holiday/";
const LOCATION = "api/location/";

const ApiConfig = {
  BASE_URL_FOR_IMAGES: "http://3.137.74.85:8080/assets/uploads/",
  BASE_URL: BASE_URL,
  //  ============= PROFILE API =============================
  GET_PROFILE: BASE_URL + PROFILE_PATH + "getprofile",
  EDIT_PERSONALDATA: BASE_URL + PROFILE_PATH + "editpersonaldtls",
  EDIT_EMPLOYEDATA: BASE_URL + PROFILE_PATH + "editemployeecontactdtl",
  MY_DAIRY: BASE_URL + "getuseractivity",
  EMPLOYEE_STATUS: BASE_URL + "activeanddeactiveuser",
  DELETE_EMPLOYEE: BASE_URL + "deleteuser",
  EDIT_EMP_PERSONAL_DETLS: BASE_URL + "editemployeepersonaldtls",
  EDIT_EMP_PROFESSIONAL_DTLS: BASE_URL + "editemployeeprofessionaldtls",
  GET_USER_PERMISSIONS: BASE_URL + "api/users/getuserpermissions/", // pass empId
  GET_PROFILE_WORK_DETAILS: BASE_URL + "api/myprofile/getprofileworkdetails",

  //  ============= user API =============================
  CREATE_ACCOUNT: BASE_URL + USER_PATH + "registeruser",
  LOGIN: BASE_URL + LOGIN_PATH + "authenticate",
  VERYFY_OTP: BASE_URL + USER_PATH + "verifyotp",
  REGISTERBUSSNESS: BASE_URL + USER_PATH + "registerbusiness",
  COUNTRY: BASE_URL + LOCATION + "getcountries",
  SECTOR: BASE_URL + LOCATION + "getsectors",
  LOGOUT: BASE_URL + "api/authentication/logout",
  // LOGIN: BASE_URL + LOGIN_PATH + "authenticate",
  FORGET_PASSWORD: BASE_URL + USER_PATH + "forgetpassword",
  CHANGE_PASSWORD: BASE_URL + USER_PATH + "changeuserpassword",
  //  ============= DASHBOARD API =============================
  GET_CHECKIN_CHECKOUT_DATA: BASE_URL + DASHBOARD_PATH + "getcheckincheckout",
  DASHBOARD_ALERT_NOTIFICATIONS:
    BASE_URL + DASHBOARD_PATH + "alertnotifications",
  TIMESHEETCOUNTS: BASE_URL + DASHBOARD_PATH + "timesheetcount",
  TIMESHEETCOUNTS2: BASE_URL + DASHBOARD_PATH + "gettotaltimesheethours",

  // http://localhost:44339/api/dashboard/timesheetcount

  //  ============= Attendance API =============================
  ATTENDANCE_LIST: BASE_URL + ATTENDANCE_PATH + "getmyattendance",
  EMPLOEE_NAME_LIST: BASE_URL + ATTENDANCE_PATH + "getemployeesnames",
  EMPLOYEE_LIST: BASE_URL + ATTENDANCE_PATH + "employeeattlist",
  UPDATE_TIME: BASE_URL + ATTENDANCE_PATH + "updateintimeouttime",
  GETINOUT_TIME: BASE_URL + ATTENDANCE_PATH + "getintimeouttime",
  ATTENDANCE_REPORT: BASE_URL + ATTENDANCE_PATH + "attendancereport",
  PUNCH_HISTORY: BASE_URL + ATTENDANCE_PATH + "getpuchhistory",
  PUNCH_HISTORY_EXPORT: BASE_URL + ATTENDANCE_PATH + "AttendanceHistoryExport",

  LEAVEAPROVE_LIST: BASE_URL + ATTENDANCE_PATH + "getemployeeapprovelist",
  GET_APPROVE_SUBlIST: BASE_URL + ATTENDANCE_PATH + "getapprovesublist",
  UPDATE_APPRPVELEAVE_LIST: BASE_URL + ATTENDANCE_PATH + "updateapprovelist",
  GET_LEAVE_LIST: BASE_URL + ATTENDANCE_PATH + "getemployeeleaveapplications",
  GET_LEAVE_TYPE: BASE_URL + ATTENDANCE_PATH + "getleavetype",
  GET_LEAVE_STRUCTURE: BASE_URL + ATTENDANCE_PATH + "getleavestructure",
  APPLY_LEAVE: BASE_URL + ATTENDANCE_PATH + "employeeleaveapply",
  GET_BRANCH_NAME_LIST: BASE_URL + "api/organization/location",
  ALL_EMPLOYEE_DOWNLOAD_EXCEL: BASE_URL + "api/attendance/allempattend",
  ATTANDANCE_USER_TRACKING: BASE_URL + "attendance/getusertracking",
  ATTANDANCE_USER_TRACKING_EXPORT: BASE_URL + "attendance/usertrackingexcel",
  GET_LEAVE_SETTING: BASE_URL + "getleavesetting?id=",
  CREATE_AND_UPDATE_LEAVE_SETTING: BASE_URL + "createAndupdateleavesetting",

  GETINOUT_TIME_C: BASE_URL + "me/Attendance/CheckInOut",

  //  ============= Attendance API =============================

  // USER_MANAGEMENT-ROLE-CREATE MODULE
  // GET_ALL_ROLE: BASE_URL + ROLE_PATH + "getroles",
  CHANGE_ROLE_STATUS: BASE_URL + ROLE_PATH + "active",
  ADD_NEW_ROLE: BASE_URL + ROLE_PATH + "createrole",
  UPDATE_ROLE: BASE_URL + ROLE_PATH + "modify",
  GET_EMPLOYEE_NAMES: BASE_URL + "api/attendance/" + "getemployeesnames",
  SEARCH_ROLE_API: BASE_URL + ROLE_PATH + "rolesearch",
  USERSTATUS: BASE_URL + "checkuserstatus",
  // USER_MANAGEMENT-ROLE-CREATE MODULE

  // USER_MANAGEMENT-ROLE-ASSIGN MODULE
  GET_ALL_APPROVE_USERS: BASE_URL + ROLE_PATH + "assignrole/getall",
  GET_ALL_ASSIGN_USERS: BASE_URL + ROLE_PATH + "searchassign",
  GET_ALL_USERNAMES: BASE_URL + USER_PATH + "userlist",
  GET_ALL_ROLE_NAMES: BASE_URL + ROLE_PATH + "getroles",
  ASSIGN_ROLE: BASE_URL + ROLE_PATH + "assignrole",
  EDIT_ASIGN_ROLE: BASE_URL + ROLE_PATH + "editassignrole",
  GET_USER_ROLE_EDIT_DATA: BASE_URL + ROLE_PATH + "getuseroles",
  ASSIGNROLE_DOWNLOAD_EXCEL: BASE_URL + "api/roles/roleassignexcel",
  ROLE_LIST_DOWNLOAD_EXCEL: BASE_URL + "api/roles/roleslistexcel",
  // USER_MANAGEMENT-ROLE-ASSIGN MODULE

  // USER MANAGEMENT => PERMISSIONS
  GET_ALL_PERMISSIONS: BASE_URL + ROLE_PATH + "permissions/getall",
  GER_PERMISSIONS_BY_ROLEID: BASE_URL + ROLE_PATH + "permissions/getbyrole",
  UPDATE_PERMISSIONS: BASE_URL + ROLE_PATH + "permissions/add",
  DELETE_ROLE: BASE_URL + "api/roles/deleterole",

  // USER MANAGEMENT => PERMISSIONS

  //  ====================== Task Management ===========================
  // GET_TASK_LIST: BASE_URL + TASK_PATH + "gettasklist",
  GET_TASK_LIST: BASE_URL + "api/taskmanagement/gettasklist",
  GET_TEAM_TASK_LIST: BASE_URL + TASK_PATH + "getteamtasklists",
  GET_PROJECT_TASK_LIST: BASE_URL + TASK_PATH + "projecttasklist",
  GET_TASK_APPROVE_LIST: BASE_URL + TASK_PATH + "taskapprovelist",
  TASK_APPROVE: BASE_URL + TASK_PATH + "approvetask",
  ADD_TASK: BASE_URL + TASK_PATH + "addtaskorupdatetask",
  GET_TASK_BY_ID: BASE_URL + TASK_PATH + "gettaskbyid",
  EMP_PROJECTS_NAMES: BASE_URL + TASK_PATH + "GetTaskProjectNames",
  PRIORITY_MASTER: BASE_URL + "api/prioritymaster/getpriority",
  //  ====================== TIME - SHEET Management ===========================
  PROJECTS_NAMES: BASE_URL + TIME_SHEET + "AssignedProjectNames",
  TIME_SHEET_DETAILS: BASE_URL + TIME_SHEET + "timesheetdetails",
  GET_MP_TASK: BASE_URL + TIME_SHEET + "getemptask",
  TIMESHEET_LIST: BASE_URL + TIME_SHEET + "timesheetentrylist",
  UPDATE_TIMESHEET: BASE_URL + TIME_SHEET + "updatetimesheet",
  ADD_TIMESHEET: BASE_URL + TIME_SHEET + "entertimesheet",
  TIME_CALENDER_HISTORY: BASE_URL + TIME_SHEET + "gettimesheethistory",
  APPROVED_PROJECT: BASE_URL + TIME_SHEET + "approveandreject",
  TIMESHEET_HISTORY: BASE_URL + TIME_SHEET + "TimesheetHistory",
  TIMESHETT_APPROVECOUNT: BASE_URL + TIME_SHEET + "TimeSheetApprcount",
  TIMESHEET_APPROVE_DETAILS:
    BASE_URL + TIME_SHEET + "TimesheetApprDetails?sort=",
  TIMESHEET_APPROVE_REJECT: BASE_URL + TIME_SHEET + "ApproveAndReject",
  DELETE_TIME_SHEET: BASE_URL + TIME_SHEET + "deletetimesheet",
  TIME_SHEET_UPDATE_VIEW: BASE_URL + TIME_SHEET + "gettimesheetdetails",
  MULTIPLE_TIMESHEET_APPROVE:
    BASE_URL + TIME_SHEET + "multipleapproveandreject",

  //  ====================== PRJECT - SHEET Management ===========================
  PROJECT_SCHEDUILE_LIST: BASE_URL + PRJECT_PATH + "ProjectScheduleList",
  PROJECT_RESOURCE_LIST: BASE_URL + PRJECT_PATH + "projectresourcelist",
  PROJECT_TEAM_TASK: BASE_URL + PRJECT_PATH + "projectteamtask",
  GETPROJECT_TEAM_TASK: BASE_URL + PRJECT_PATH + "GetProjectResourceList",

  //  ====================== ORGANIZATION - SHEET Management ===========================
  HOLIDAY_EVENT_API: BASE_URL + ORGANIZATION_PATH + "gethoildayandevents",
  CRATE_ANNOUNCEMENT: BASE_URL + ORGANIZATION_PATH + "createannouncement",
  ORGANIZATION_BRANCH: BASE_URL + ORGANIZATION_PATH + "organizationbranches",
  ORGANIZATION_TEAM: BASE_URL + ORGANIZATION_PATH + "organizationteams",
  EMPLOYEE_NAMES: BASE_URL + ORGANIZATION_PATH + "getemployeesnames",
  MY_ANNOUNCEMENTS: BASE_URL + ORGANIZATION_PATH + "getmyannouncements",
  UPDATE_ANNOUNCEMENTS: BASE_URL + ORGANIZATION_PATH + "updateannoucement",
  DELETE_ANNOUNCEMENTS: BASE_URL + ORGANIZATION_PATH + "deleteanouncement",
  PREVIEW_ANNOUNCEMENTS:
    BASE_URL + ORGANIZATION_PATH + "getannoucementpreviewlist",
  ALL_ANNOUNCEMENTS_LIST:
    BASE_URL + ORGANIZATION_PATH + "getallannoucementlist",
  UPDATE_VIEW_COUNT: BASE_URL + ORGANIZATION_PATH + "updateviewedcount",
  VIEW_MEMBER_LIST: BASE_URL + ORGANIZATION_PATH + "viewmemberlist",
  GET_ORGANIZATION_LIST: BASE_URL + ORGANIZATION_PATH + "getorganization",
  UPDATE_ORGANIZATION: BASE_URL + ORGANIZATION_PATH + "organizationmodify",
  GET_DEPARMENT_LIST: BASE_URL + ORGANIZATION_PATH + "getdepartmentlist",
  DEPARTMENT_MODIFY: BASE_URL + ORGANIZATION_PATH + "departmentmodify",
  GET_DESIGNATION_LIST: BASE_URL + ORGANIZATION_PATH + "designationlist",
  CREATE_DESIGNATION: BASE_URL + ORGANIZATION_PATH + "createdesignation",
  DELETE_DESIGNATION: BASE_URL + ORGANIZATION_PATH + "deletedesignation",
  GET_DESIGNATION: BASE_URL + ORGANIZATION_PATH + "getdesignation",
  GET_MANAGE_LOCATION: BASE_URL + ORGANIZATION_PATH + "location",
  CREATE_LOCATION: BASE_URL + ORGANIZATION_PATH + "createlocation",
  DELETE_LOCATION: BASE_URL + ORGANIZATION_PATH + "deletelocation",

  GET_USER_DETAILS: BASE_URL + ORGANIZATION_PATH + "userdetails",
  CHANGE_USER_STATUS: BASE_URL + ORGANIZATION_PATH + "userstatus",
  GET_MANAGE_SHIFT: BASE_URL + ORGANIZATION_PATH + "manageshift",
  GET_SINGLE_SHIFT: BASE_URL + ORGANIZATION_PATH + "getshiftmaster",
  SHIFT_MASTER_MODIFY: BASE_URL + ORGANIZATION_PATH + "shiftmastermodify",
  GET_ALL_SHIFT: BASE_URL + "api/shiftmaster/getallshift",
  ADD_SHIFT_MASTER: BASE_URL + "api/shiftmaster/addShiftMaster",
  UPDATE_SHIFT_MASTER: BASE_URL + "api/shiftmaster/updateshiftmaster/",
  DELETE_SHIFT_MASTER: BASE_URL + "api/shiftmaster/deleteShift/",
  EDIT_SHIFT_MASTER: BASE_URL + "api/shiftmaster/getshiftbyid?id=",
  CALENDAR_SETTING_ADD: BASE_URL + "api/holiday/calendarsettings",
  GET_CALENDER_SETTING_BY_ROLE:
    BASE_URL + "api/holiday/getcalendarsettings?RoleId=",
  GET_ACEDEMIC_DETAILS: BASE_URL + "api/holiday/getacademicyearslist",
  DELETE_CALENDER_YEAR: BASE_URL + "api/holiday/deleteacademicyear?id=",
  CREATE_ACEDEMIC_YEAR: BASE_URL + "api/holiday/createacademicyear",
  GET_SINGLE_ACEDMIC_YEAR: BASE_URL + "api/holiday/getacademicyear?id=",

  //==============Node apis for chat===============
  SEARCH_ALL: BASE_URL_CHAT + "/search-all?search_key=",
  CREATE_CHATROOM: BASE_URL_CHAT + "/chat/private-chat",
  GET_PRIVATE_CHAT_CONVERSATION:
    BASE_URL_CHAT + "/chat/private-chat/get-conversation?schannel_id=",
  GET_RECENTCHATS: BASE_URL_CHAT + "/chat/private-chat/get-chats-list",
  UPLOAD_CHAT_FILES: BASE_URL_CHAT + "/chat/private-chat/handleUpload",
  PIN_CHAT: BASE_URL_CHAT + "/chat/private-chat/pin-chat",
  UNPIN_CHAT: BASE_URL_CHAT + "/chat/private-chat/unpin-chat",
  SET_STATUS: BASE_URL_CHAT + "/chat/private-chat/change-status",
  GET_FILES: BASE_URL_CHAT + "/chat/private-chat/getFiles?schannel_id=",
  CREATE_SUB_CHANNEL: BASE_URL_CHAT + "/chat/group-chat/create-subchannel",

  CREATE_Task: BASE_URL + "api/taskmanagement/addtaskorupdatetask",

  // ============ TASK MANAGEMENT (SUB MODULE) ========
  GET_TASK_PROJECTLIST: BASE_URL + "api/taskmanagement/projectlist",
  GET_PROJECT_LIST_ADMIN: BASE_URL + "api/taskmanagement/tasklistbyadmin",
  POST_TASK_BY_ADMIN: BASE_URL + "api/taskmanagement/addtaskbypjtadmin",
  EDIT_TASK_BY_ID: BASE_URL + "api/taskmanagement/getprojecttaskbyid",
  GET_TASK_NAME_MODAL: BASE_URL + "api/taskmanagement/standardtasklist",
  UPDATE_TASK_BY_ADMIN: BASE_URL + "api/taskmanagement/updatetaskbyadmin",
  Delete_Task_By_Id: BASE_URL + "api/taskmanagement/deletetasklist",
  GET_TASK_DOWNLOAD_EXCEL: BASE_URL + "api/taskmanagement/gettaskexcelsheet",
  // Task Allocation list (SUB MODULE) ===========
  GET_TASK_ALLOCATION_LIST: BASE_URL + "api/taskmanagement/taskallocationlist",
  GET_EMPLOYEE_NAME_LIST:
    BASE_URL + "api/taskmanagement/empnamebyprojectassign",
  SAVE_TASK_ALLOCATION: BASE_URL + "api/taskmanagement/taskallocations",
  GET_OUTSOURCE_ALLOCATION_LIST:
    BASE_URL + "api/taskmanagement/empnameoutsourcing",

  // Abort Task (SUB MODULE) ===========
  ABORT_TASK_LIST_DATA: BASE_URL + "api/taskmanagement/aborttasklist",
  ABORT_TASK_POST: BASE_URL + "api/taskmanagement/aborttask",
  ABORT_TASK_DOWNLOAD_EXCEL: BASE_URL + "api/taskmanagement/aborttaskexcel",

  // Hold Task (SUB MODULE) ===========
  HOLD_TASK_LIST: BASE_URL + "api/taskmanagement/holdtasklist",
  HOLD_TASK_CLICK: BASE_URL + "api/taskmanagement/holdtask",
  HOLD_TASK_DOWNLOAD_EXCEL: BASE_URL + "api/taskmanagement/holdtaskexcel",

  // Standard Task (SUB MODULE) ===========
  GET_TEAMS_LIST_STANDARD_TASK: BASE_URL + "api/taskmanagement/getteams",
  ADD_STANDARD_TASK: BASE_URL + "api/taskmanagement/addstandardtask",
  STANDARD_TASK_BY_ID: BASE_URL + "api/taskmanagement/standardtaskbyid",
  UPDATE_STANDARD_TASK_LIST: BASE_URL + "api/taskmanagement/updatestandardtask",
  DELETE_STANDARD_TASK: BASE_URL + "api/taskmanagement/deletestandardtask",
  STANDARDTASK_DOWNLOAD_EXCEL:
    BASE_URL + "api/taskmanagement/standardtaskexcel",
  TSAKALLOCATION_DOWNLOAD_EXCEL:
    BASE_URL + "api/taskmanagement/taskallocationexcel",

  // Complete Task (SUB MODULE) ===========
  GET_LIST_OF_COMPLETE_TASK: BASE_URL + "api/taskmanagement/taskcompletionlist",
  ABORT_COMPLETE_TASK: BASE_URL + "api/taskmanagement/completetask",
  COMPLETETASK_DOWNLOAD_EXCEL:
    BASE_URL + "api/taskmanagement/taskcompletelistexcel",

  // Team Task (SUB MODULE) ===========
  TEAMTASK_DOWNLOAD_EXCEL: BASE_URL + "api/taskmanagement/teamtaskexcel",

  // Project Task (SUB MODULE) ===========
  PROJECTTASK_DOWNLOAD_EXCEL: BASE_URL + "api/taskmanagement/projecttaskexcel",

  // Export File  (SUB MODULE) ===========
  DOWNLOAD_EXCEL_FILE: BASE_URL + "api/taskmanagement/excelformat",
  POST_EXCEL_FILE_FORMAT: BASE_URL + "api/taskmanagement/importexcel",

  // Client management APis
  GET_CLIENT_MANAG_LIST: BASE_URL + "api/projectclientmaster/getprjclientlist",
  ADD_CLIENT_DATA: BASE_URL + "api/projectclientmaster/client",
  EDIT_CLIENT_DATA: BASE_URL + "api/projectclientmaster/client",
  DELETE_CLIENT_BY_ID: BASE_URL + "api/projectclientmaster/deleteclient",
  GET_UPDATE_CLIENT_DATA: BASE_URL + "api/projectclientmaster/getclient",
  GET_PROJECT_LIST_DD: BASE_URL + "api/attendance/getprojectlist",
  CLIENTMANG_DOWNLOAD_EXCEL:
    BASE_URL + "api/projectclientmaster/clientexcelformat",

  // DAshboard Apis
  GET_INPROGRESS_DATA_LIST: BASE_URL + "api/dashboard/taskinprogress",
  GET_COMPLETED_DATA_LIST: BASE_URL + "api/dashboard/completetask",
  GET_TOTAL_WORK_HOURS: BASE_URL + "api/dashboard/gettotaltimesheethours",

  // Project module
  GET_MODAL_CLIENT_OPTIONS: BASE_URL + "api/project/getclientlist",
  GET_DEPARTMENT_MODAL_OPTIONS: BASE_URL + "api/project/getdepartmentlist",
  GET_ADMIN_NAMES_MODAL_OPTIONS: BASE_URL + "api/project/getemployeelist",
  GET_PROJECT_LIST: BASE_URL + "api/project/getprojectlist",
  ADD_PROJECT: BASE_URL + "api/project/addproject",
  POST_DATA_WITH_PROJECT_ID: BASE_URL + "api/project/editbyprojectid",
  DELETE_PROJECT: BASE_URL + "api/project/deleteproject",
  UPDATE_PROJECT: BASE_URL + "api/project/updateproject",
  GET_EMPLOYEE_NAMES_LIST: BASE_URL + "api/timesheet/employeenames",
  POST_EMPLOYEE_CREDENTIALS: BASE_URL + "api/timesheet/employeetimesheets",
  GET_PROJECTNAMES_LIST: BASE_URL + "api/timesheet/AllProjectNames",
  POST_TIMESHEET_DETAILS: BASE_URL + "api/timesheet/projecttimesheet",
  //POST_TIMESHEET_DETAILS: BASE_URL + "api/project/getemployeeallocationlist",
  GET_OWNER_NAMES_MODEL_OPTIONS:
    BASE_URL + "api/project/getemployeeallocationlist",

  EMPTIME_DOWNLOAD_EXCEL:
    BASE_URL + "api/timesheet/employeetimesheetdetailexcel",
  PROTIME_DOWNLOAD_EXCEL:
    BASE_URL + "api/timesheet/projecttimesheetdetailsexcel",
  PROJRESOURCE_DOWNLOAD_EXCEL: BASE_URL + "api/project/projectresourceexcel",
  PROJRESOURCE_LIST_DOWNLOAD_EXCEL:
    BASE_URL + "api/project/projectrescourcelistexcel",
  PROJECT_DOWNLOAD_EXCEL: BASE_URL + "api/project/getprojectexcelsheet",

  // PROJECT DOCUMENTS
  GET_SALARY_SLIP: BASE_URL + PROJECT_PATH + "getsalaryslip",
  //EMPLOYEE_CODE:BASE_URL+"me/document/addeditemployeecode",
  //GET_EMPLOYEE_BY_ID:BASE_URL+'me/document/getemployeecode?locationId=',  // pass Id + OrgId
  // PROJECT DOCUMENTS

  // PROJETC SCHEDUL APIs
  GET_ASSIGNED_PROJECT_LIST: BASE_URL + PROJECT_PATH + "assignmentlist",
  GET_PROJECTS_NAMES: BASE_URL + PROJECT_PATH + "getprojectlist",
  GET_DROPDOWN_EMPLOYEE_NAMES: BASE_URL + PROJECT_PATH + "getemployeelist",
  ASSIGN_PROJECT: BASE_URL + PROJECT_PATH + "projectassingement",
  DEASSIGN_PROJECT: BASE_URL + PROJECT_PATH + "projectdeassigned",

  // COMPLIANCE MODULE APIs
  GET_DROPDOWNEMPLOYEE_LIST: BASE_URL + "compliance/teamnotassigndropdown",

  GET_TEAMNOTASSIGNEDLIST_DATA: BASE_URL + "compliance/teamnotassignedlist",
  GET_TEAMNOTASSIGNEDLIST_EXPORT:
    BASE_URL + "compliance/teamNotassignedlistexcelformat",

  GET_PROJECTNOTASSIGNED_LIST: BASE_URL + "compliance/projectnotassign",
  GET_PROJECTNOTASSIGNED_EXPORT:
    BASE_URL + "compliance/projectnotassignexcelsheet",

  GET_PROJECTASSIGNED_LIST: BASE_URL + "compliance/assignedlist",
  GET_PROJECTASSIGNED_EXPORT: BASE_URL + "compliance/assignedlistexcel",

  GET_PROJECTDEASSIGN_LIST: BASE_URL + "compliance/deassignedlist",
  GET_PROJECTDEASSIGN_LIST_EXPORT: BASE_URL + "compliance/DeAssignedListExcel",

  GET_TWOORMOREPROJECTS_LIST: BASE_URL + "compliance/morethantwoproject",
  GET_PROJECTNOTADDED_LIST: BASE_URL + "compliance/tasknotadded",
  GET_PROJECTNOTADDED_EXPORT: BASE_URL + "compliance/tasknotaddedexcel",

  GET_PROJECTNOTAPPROVED_LIST: BASE_URL + "compliance/tasknotapproved",
  GET_TASKNOTAPPROVED_EXPORT: BASE_URL + "compliance/tasknotapprovedexcel",

  GET_TIMESHEETNOTENTERED_LIST: BASE_URL + "compliance/timesheetnotentered",
  GET_TIMESHEETNOTENTERED_LIST_EXPORT:
    BASE_URL + "compliance/timesheetnotenteredexcel",

  GET_TIMESHEETNOTAPPROVED_LIST: BASE_URL + "compliance/timesheetnotapproved",
  GET_TIMESHEETNOTAPPROVED_EXPORT:
    BASE_URL + "compliance/timesheetnotapprovedexcel",

  GET_TIMEPERIODOVER_LIST: BASE_URL + "compliance/timeperiodover",
  GET_TIMEPERIODOVER_LIST_EXPORT: BASE_URL + "compliance/timeperiodoverexcel",

  GET_BENCHLIST_DATA: BASE_URL + "compliance/benchlist",
  GET_BENCHLIST_EXPORT: BASE_URL + "compliance/benchlistexcel",

  TEAMNOTAASIGN_DOWNLOAD_EXCEL:
    BASE_URL + "compliance/teamnotassignedlistexcelformat",

  TWOPLUSPROJ_DOWNLOAD_EXCEL: BASE_URL + "compliance/morethantwoprojectexcel",
  PROJ_DEASSIGN_DOWNLOAD_EXCEL: BASE_URL + "compliance/deassignedlistexcel",

  // Compliance Setting Api
  GET_COMPLIANCE_SETTINGS: BASE_URL + COMPLIANCE + "getcompliancesettings",
  SET_COMPLIANCE_SETTINGS: BASE_URL + COMPLIANCE + "setcompliancesettings",
  SET_GENERIC_USERS: BASE_URL + COMPLIANCE + "setgenericusers",
  GET_GENERIC_USER_MAPPING: BASE_URL + COMPLIANCE + "getgenericusermapping/",

  // Project Costing
  GET_DROPDOWNTEAMNAME_LIST: BASE_URL + "api/project/teamnames",
  GET_DESIGNATIONDD_LIST: BASE_URL + "api/project/designationsname",
  POST_COST_DATA: BASE_URL + "api/project/addemployeecosting",
  GET_EMPLOYEE_LIST: BASE_URL + "api/project/costinglist",
  GET_DATA_WITH_ID: BASE_URL + "api/project/getbyidcost",
  POST_UPDATE_COST: BASE_URL + "api/project/updatecosting",
  DELETE_SINGLE_DATA: BASE_URL + "api/project/deletecost",
  GET_PROJECTFORECAST_LIST: BASE_URL + "api/project/projectcostingreport",
  POST_WITH_ID_FORCAST: BASE_URL + "api/project/getprojectforcast",
  GET_EMPLOYEE_SALARY_STRUCTURE: BASE_URL + PROJECT_PATH + "employeestructure",
  ADD_UPDATE_SALARY_STRUCTURE: BASE_URL + PROJECT_PATH + "addsalarystructure",
  DELETE_SALARY_STRUCTURE: BASE_URL + PROJECT_PATH + "deletesalarystructure",
  GET_SALARY_DATA: BASE_URL + PROJECT_PATH + "generatesalaryslip",
  GENERATE_SALARY_SLIP: BASE_URL + PROJECT_PATH + "generatepdfsalaryslip",
  // https://api.wifiattendance.com/api/project/generatepdfsalaryslip

  // https://api.wifiattendance.com/api/project/generatesalaryslip?EmpId=4966&Months=april&year=2023

  // TeamPreference
  CREATE_TEAM: BASE_URL + "api/teampreference/createteam",
  TEAM_HEAD_lIST: BASE_URL + "api/attendance/getemployeesnames",
  GET_TEAM_LIST: BASE_URL + "api/teampreference/getteamlist",
  UPDATE_TEAM: BASE_URL + "api/teampreference/updateteams",
  ADD_MEMBER:
    BASE_URL +
    "api/teampreference/addmember?EmployeeId=${EmployeeId}&TeamId=${teamId}",
  MANAGE_TEAM_DOWNLOAD_EXCEL: BASE_URL + "api/teampreference/teamslistexcel",
  DELETE_TEAMLIST: BASE_URL + "api/teampreference/deleteteam/",

  // Holiday and calendar API's
  GET_HOLIDAYS_LIST: BASE_URL + HOLIDAY_PATH + "getholidaylist",
  CREATE_HOLIDAY: BASE_URL + HOLIDAY_PATH + "create",
  GET_HOLIDAY: BASE_URL + HOLIDAY_PATH + "getholiday",
  DELETE_HOLIDAY: BASE_URL + HOLIDAY_PATH + "delete",
  UPDATE_HOLIDAY: BASE_URL + HOLIDAY_PATH + "modify",
  GET_LOCATION: BASE_URL + "getlocations",

  // Compliance
  GETDEPARTMENT_LIST: BASE_URL + "api/project/getdepartmentlist",
  DESIGNATION_NAME: BASE_URL + "api/project/designationsname",
  ROLES_LIST: BASE_URL + "api/roles/getroles",
  GET_LOCATION: BASE_URL + "getlocations",
  GET_SHIFT_LIST: BASE_URL + "getshifts",

  GET_USER_NOTLOGIN: BASE_URL + "getusernotlogin",
  GET_USER_NOTLOGIN_EXPORT: BASE_URL + "GetUserNotloginexport",

  GET_USER_LASTLOGIN: BASE_URL + "UserLastLogin",
  GET_USER_LASTLOGIN_EXPORT: BASE_URL + "UserLastLoginExport",

  GET_USER_ACTIVITY: BASE_URL + "UserActivityHistory",
  GET_USER_ACTIVITY_EXPORT: BASE_URL + "UserActivityExport",

  //Employee
  ACTIVE_EMPLOYEE_LIST: BASE_URL + "activeemployees",
  EMPLOYE_ADDED: BASE_URL + "employeeadd",
  DEACTIVE_EMP_LIST: BASE_URL + "getdeactiveuser",
  IMPORT_EMPLOYEE: BASE_URL + "importemployeesfromexcel",
  Excel_User: BASE_URL + "exporttoexceluser",
  DELETE_USER: BASE_URL + "deleteuser",
  USER_PROFILE_WORK_DETAILS:
    BASE_URL + "api/myprofile/getemployeeworkdetails?employeeId=",
  //Timesheet Management
  DOWNLOAD_TIMESHEET_EXCEL: BASE_URL + "api/timesheet/timesheetexcel",

  // Years

  GET_YEARS: BASE_URL + "api/attendance/dynamic/years",
  GET_MONTH: BASE_URL + "api/attendance/dynamic/months?Year=",

  //EMPLOYEE MANAGMENT
  EMPLOYEELIST_DOWNLOAD_EXCEL: BASE_URL + "activeemployeesexcel",
  DEACTIVE_EMPLOYEELIST_DOWNLOAD_EXCEL: BASE_URL + "deactivateuserexcel",

  //General Settings
  CHANGE_PASSWORD_BY_ADMIN: BASE_URL + "api/authentication/changepassword",

  // organetion
  GET_DESIGNEGETION_LIST: BASE_URL + "api/designationmaster/getdesignationlist",
  ADD_DESIGNEGETION: BASE_URL + "api/organization/createdesignation",
  DELETE_DESIGNEGETION: BASE_URL + "api/organization/deletedesignation?id={id}",
  GET_DEPARMENT_LIST: BASE_URL + "api/organization/getdepartmentlist",
  ADD_DEPARTMENT: BASE_URL + "api/departmentmaster/createdepartment",
  UPDATE_DEPARMENT: BASE_URL + "api/organization/departmentmodify",
  DELETE_DEPARMENT: BASE_URL + "api/departmentmaster/deletedepartment/",
  GET_LOCATION: BASE_URL + "api/organization/location",
  ADD_UPDATE_LOCATION: BASE_URL + "api/organization/createlocation",
  EMPLOYEESlIST: BASE_URL + "api/attendance/getemployeesnames",
  DELETE_LOCATION: BASE_URL + "api/organization/deletelocation?id={id}",
  GET_ORGANISATION: BASE_URL + "api/organization/getorganization",
  UPDATE_ORGANIZATIONS: BASE_URL + "api/organization/organizationmodify",

  // payment get way
  PAYMENTGETWAY: BASE_URL + "api/organization/gatewaymaster/",
  PAYMENTGETDETAILS: BASE_URL + "api/organization/getpaymentsettings/",
  UPDATEPAYMENT: BASE_URL + "api/organization/updatepaymentsettings",

  // SMS GETWAY
  SMSGETWAYDETAILS: BASE_URL + "api/organization/getsmssettings/",
  UPDATESMS: BASE_URL + "api/organization/updatesmssettings",

  // WhatsApp Settings
  GETWHATSAPPLIST: BASE_URL_Offoce_caller + "/api/WA-Setting?",
  GETWHATSAPPDETAILS: BASE_URL_Offoce_caller + "/api/WA-Setting/",
  GETWHATSAPPADD: BASE_URL_Offoce_caller + "/api/WA-Setting",
  GETWHATSAPPUPDATE: BASE_URL_Offoce_caller + "/api/WA-Setting",
  GETWHATSAPPDELETE: BASE_URL_Offoce_caller + "/api/WA-Setting/",
};

export default ApiConfig;
