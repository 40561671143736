import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import { Link, useNavigate } from "react-router-dom";
import {
  PostCallWithErrorResponse,
  simpleGetCallWithToken,
  simpleGetCallWithErrorResponse,
  deleteWithAuthCall,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import ImagePic from "../../assets/images/createUser.png";
import Loader from "../Loader";
import NoData from "../../sharedComponent/NoData";
import Pagination from "../../sharedComponent/Pagination";
import view from "../../assets/images/view.svg";
import DelectAc from "../../assets/images/DelectAc.svg";
import { notifyError, notifySuccess } from "../../toaster/notify";
import Modal from "react-bootstrap/Modal";
import ic_excel from "../../assets/images/ic_excel.svg";
import FileSaver from "file-saver";
import ExcelLoader from "../../sharedComponent/ExcelLoader";
import default_img from "../../assets/images/defailt_icon.png";

const List = () => {
  const navigate = useNavigate("");
  const { sidebar, setSidebar, Dark, setDark, userData, permissions } =
    useContext(AppContext);
  const [EmployeeList, setEmployeeList] = useState([]);
  const [DeactmployeeList, setDeactmployeeList] = useState([]);
  const [showDeactive, setShowDeactive] = useState();
  console.log("showDeactive---> ", showDeactive);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(25);
  const [userId, setUserId] = useState(0);
  const [loading, setLoading] = useState(false);
  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);
  const [isActive, setIsActive] = useState(false);

  const [empoyeeName, setEmpoyeeName] = useState("");

  const [excelLoading, setExcelLoading] = useState(false);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const [DeleteTaskList, setDeleteTaskList] = useState("");

  // const [sortOrder, setSortOrder] = useState('asc');
  // const [employees, setEmployees] = useState(["Pune", "Aurangabad", "Trivandrum", ""]);

  // const [sortOrder, setSortOrder] = useState('asc');
  // const [employees, setEmployees] = useState(["Pune", "Aurangabad", "Trivandrum", ""]);

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };
  // const searchedDataPageCounts =
  //     adminList &&
  //     adminList.length &&
  //     adminList.filter((item) =>
  //         item.ProjectName?.toLowerCase().includes(searchValue)
  //     );

  useEffect(() => {
    getEmployListApi();
    getDeActiveUsersListAPI();
    console.log("imployList--->", EmployeeList);
  }, []);

  const handlePhotoUrl = (photo) => {
    console.log(
      "EmployeeList photo--->",
      ApiConfig.BASE_URL_FOR_IMAGES + photo?.PhotoPath
    );
    console.log("EmployeeList all data --->", photo);
  };

  const onDeleteEmplyes = (id) => {
    const body = {
      Empid: id,
    };
    deleteWithAuthCall();
  };

  // const handleSortByLocation = () => {
  //   const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
  //   setSortOrder(newSortOrder);

  //   const sortedEmployees = employees.slice().sort((a, b) => {
  //     // Check if WorkLocation property exists for both employees
  //     if (a.WorkLocation && b.WorkLocation) {
  //       // Use localeCompare for alphabetical sorting
  //       return newSortOrder === 'asc'
  //         ? a.WorkLocation.localeCompare(b.WorkLocation)
  //         : b.WorkLocation.localeCompare(a.WorkLocation);
  //     } else {
  //       // Handle case where WorkLocation property is missing
  //         alert("data not avil"); // No change in order
  //     }
  //   });

  //   setEmployees(sortedEmployees);
  // };

  const getEmployListApi = () => {
    setLoading(true);
    simpleGetCallWithToken(ApiConfig.ACTIVE_EMPLOYEE_LIST)
      .then((res) => {
        setLoading(false);
        console.log(res);
        setEmployeeList(res.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeRoleStatusHandler = async (id) => {
    let dataObj = {
      Empid: id || 0,
    };
    try {
      const response = await PostCallWithErrorResponse(
        ApiConfig.EMPLOYEE_STATUS,
        JSON.stringify(dataObj)
      );
      if (!response.error) {
        notifySuccess("Successfully done");

        getEmployListApi();

        getDeActiveUsersListAPI();
      } else {
        notifyError("Something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const downloadExcelFile = (e) => {
    setExcelLoading(true);
    e.preventDefault();
    simpleGetCallWithErrorResponse(
      showDeactive == 2
        ? ApiConfig.DEACTIVE_EMPLOYEELIST_DOWNLOAD_EXCEL
        : ApiConfig.EMPLOYEELIST_DOWNLOAD_EXCEL
    )
      .then((res) => {
        console.log("Download File Response", res);
        if (res.error) {
          console.log("Error response", res);
        } else {
          if (res.json.Success === true) {
            setExcelLoading(false);
            let data = res.json.Data;
            FileSaver.saveAs(
              "data:" + data.ContentType + ";base64," + data.FileContents,
              data.FileDownloadName
            );
          } else {
            console.log("res", res);
            setExcelLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDeActiveUsersListAPI = () => {
    setLoading(true);
    PostCallWithErrorResponse(ApiConfig.DEACTIVE_EMP_LIST)
      .then((res) => {
        setLoading(false);
        console.log("De active employee list data res----->", res);
        setDeactmployeeList(res?.json.Data);
        console.log("Response with data----->", res?.json.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteDesignation = async (id) => {
    console.log("delete Id--->", id);
    let dataObj = {
      Empid: id || 0,
    };
    try {
      const response = await PostCallWithErrorResponse(
        ApiConfig.DELETE_USER,
        JSON.stringify(dataObj)
      );
      console.log("Delete Response--->", response);
      if (!response.error) {
        notifySuccess("Employee deleted successfully");
        getEmployListApi();
      } else {
        notifyError("Something went wrong");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const ImageWithFallback = ({ src, alt, fallbackSrc }) => {
    const handleError = (event) => {
      event.target.src = fallbackSrc;
    };

    return (
      <img
        src={src}
        alt={alt}
        onError={handleError}
        style={{ width: "30px", height: "30px" }}
      />
    );
  };
  const searchedDataPageCounts =
    EmployeeList.length &&
    DeactmployeeList &&
    EmployeeList.filter &&
    DeactmployeeList.filter((item) =>
      item.EmployeeName?.toLowerCase().includes(searchValue)
    );

  const displayTaskList =
    EmployeeList &&
    EmployeeList.slice(startIndex, endIndex).map((emply, index) => {
      return (
        <tr className="table-row-custom">
          <td>{index + 1 + startIndex}</td>
          <td>
            <ImageWithFallback
              src={ApiConfig.BASE_URL_FOR_IMAGES + emply?.PhotoPath}
              alt="Main Image"
              fallbackSrc={default_img}
            />
            {/* <img
              style={{ width: "30px", height: "30px" }}
              src={
                !emply.PhotoPath
                  ? ImagePic
                  : //: emply.PhotoPath
                  emply.PhotoPath.length
                  ? ApiConfig.BASE_URL_FOR_IMAGES + emply?.PhotoPath
                  : emply.PhotoPath && URL.createObjectURL(emply.PhotoPath)
                
              }
              alt=""
            /> */}
          </td>
          <td>{emply.EmployeeName} </td>
          <td>{emply.Email}</td>
          <td>{emply.Department}</td>
          <td>{emply.DateOfJoin}</td>
          <td>{emply.WorkLocation}</td>
          {/* <td>Active</td> */}
          <td
            onClick={() => {
              setUserId(emply?.EmployeeId);
              setIsActive(emply?.IsActive);
              setEmpoyeeName(emply?.EmployeeName);
              handleShow3();
              //changeRoleStatusHandler(emply.EmployeeId);
            }}
            className={
              emply?.IsActive === true ? "green-active" : "red-deactive"
            }
          >
            {emply?.IsActive === true ? "Active" : "De-active"}
          </td>
          {permissions && permissions["View Employee Profile"] && (
            <td>
              &nbsp; &nbsp; &nbsp;
              <img
                src={view}
                className="mx-2 editBtn"
                onClick={() => {
                  navigate("/Profile/" + emply.EmployeeId);
                }}
                alt=""
              />
              {permissions && permissions["Delete Employee"] && (
                <img
                  onClick={() => {
                    handleShow4();
                    setDeleteTaskList(emply.EmployeeId);
                    //   deleteDesignation(emply.EmployeeId);
                  }}
                  src={DelectAc}
                  alt=""
                />
              )}
            </td>
          )}
        </tr>
      );
    });

  //De-active user list

  const DeActiveUsers =
    DeactmployeeList &&
    DeactmployeeList.slice(startIndex, endIndex).map((emply2, index) => {
      return (
        <tr className="table-row-custom">
          <td>{index + 1 + startIndex}</td>
          {console.log("DeactmployeeList data ----", DeactmployeeList)}
          <td>
            <ImageWithFallback
              src={ApiConfig.BASE_URL_FOR_IMAGES + emply2?.PhotoPath}
              alt="Main Image"
              fallbackSrc={default_img}
            />

            {/* <img
              style={{ width: "30px", height: "30px" }}
              src={
                !emply2.PhotoPath
                  ? ImagePic
                  : emply2.PhotoPath.length
                  ? ApiConfig.BASE_URL_FOR_IMAGES + emply2.PhotoPath
                  : emply2.PhotoPath && URL.createObjectURL(emply2.PhotoPath)
              }
              alt=""
            /> */}
          </td>
          <td>{emply2.EmployeeName}</td>
          <td>{emply2.Email}</td>
          <td>{emply2.Department}</td>
          <td>{emply2.DateOfJoin}</td>
          <td>{emply2.WorkLocation}</td>
          {/* <td>Active</td> */}
          <td
            onClick={() => {
              setUserId(emply2?.EmployeeId);
              setIsActive(emply2?.IsActive);
              setEmpoyeeName(emply2?.EmployeeName);
              handleShow3();
              //changeRoleStatusHandler(emply.EmployeeId);
            }}
            className="text-danger"
          >
            {emply2?.IsActive === false ? "De-active" : "Active"}
          </td>
          {permissions && permissions["View Employee Profile"] && (
            <td>
              &nbsp; &nbsp; &nbsp;
              <img
                src={view}
                className="mx-2 editBtn"
                onClick={() => {
                  navigate("/Profile/" + emply2.EmployeeId);
                }}
                alt=""
              />
              {permissions && permissions["Delete Employee"] && (
                <img
                  onClick={() => {
                    handleShow4();
                    setDeleteTaskList(emply2.EmployeeId);
                    //   deleteDesignation(emply.EmployeeId);
                  }}
                  src={DelectAc}
                  alt=""
                />
              )}
            </td>
          )}
        </tr>
      );
    });

  //De active search
  const DeactivedisplaySearchedTaskList =
    DeactmployeeList &&
    DeactmployeeList?.filter(
      (emply2) =>
        emply2.EmployeeName?.toLowerCase().includes(
          searchValue.toLowerCase()
        ) ||
        emply2.WorkLocation?.toLowerCase().includes(searchValue.toLowerCase())
    )
      .slice(startIndex, endIndex)
      .map((emply2, index) => {
        return (
          <tr className="table-row-custom">
            <td>{index + 1 + startIndex}</td>
            <td>
              {/* <img
                style={{ width: "30px", height: "30px" }}
                src={
                  !emply2.PhotoPath
                    ? ImagePic
                    : emply2.PhotoPath.length
                    ? ApiConfig.BASE_URL_FOR_IMAGES + emply2.PhotoPath
                    : emply2.PhotoPath && URL.createObjectURL(emply2.PhotoPath)
                }
                alt=""
              /> */}
              <ImageWithFallback
                src={ApiConfig.BASE_URL_FOR_IMAGES + emply2?.PhotoPath}
                alt="Main Image"
                fallbackSrc={default_img}
              />
            </td>
            <td>{emply2.EmployeeName}</td>
            <td>{emply2.Email}</td>
            <td>{emply2.Department}</td>
            <td>{emply2.DateOfJoin}</td>
            <td>{emply2.WorkLocation}</td>
            {/* <td>Active</td> */}
            <td
              onClick={() => {
                setUserId(emply2?.EmployeeId);
                setIsActive(true);
                setEmpoyeeName(emply2?.EmployeeName);
                handleShow3();
              }}
              className="text-danger"
            >
              {!emply2?.IsActive ? "De-active" : "Active"}
            </td>
            {permissions && permissions["View Employee Profile"] && (
              <td>
                &nbsp; &nbsp; &nbsp;
                <img
                  src={view}
                  className="mx-2 editBtn"
                  onClick={() => {
                    navigate("/Profile/" + emply2.EmployeeId);
                  }}
                  alt=""
                />
                {permissions && permissions["Delete Employee"] && (
                  <img
                    onClick={() => {
                      handleShow4();
                      setDeleteTaskList(emply2.EmployeeId);
                      //   deleteDesignation(emply.EmployeeId);
                    }}
                    src={DelectAc}
                    alt=""
                  />
                )}
              </td>
            )}
          </tr>
        );
      });

  //Search
  const displaySearchedTaskList =
    EmployeeList &&
    EmployeeList?.filter(
      (emply) =>
        emply.EmployeeName?.toLowerCase().includes(searchValue.toLowerCase()) ||
        emply.WorkLocation?.toLowerCase().includes(searchValue.toLowerCase())
    )
      .slice(startIndex, endIndex)
      .map((emply, index) => {
        return (
          <tr className="table-row-custom">
            <td>{index + 1 + startIndex}</td>
            <td>
              {/* <img
                style={{ width: "30px", height: "30px" }}
                src={
                  !emply.PhotoPath
                    ? ImagePic
                    : emply.PhotoPath
                    ? ApiConfig.BASE_URL_FOR_IMAGES + emply.PhotoPath
                    : emply.PhotoPath && URL.createObjectURL(emply.PhotoPath)
                }
                alt=""
              /> */}
              <ImageWithFallback
                src={ApiConfig.BASE_URL_FOR_IMAGES + emply?.PhotoPath}
                alt="Main Image"
                fallbackSrc={default_img}
              />
            </td>
            <td>{emply.EmployeeName}</td>
            <td>{emply.Email}</td>
            <td>{emply.Department}</td>
            <td>{emply.DateOfJoin}</td>
            <td>{emply.WorkLocation}</td>
            {/* <td>Active</td> */}
            <td
              onClick={() => {
                setUserId(emply?.EmployeeId);
                setIsActive(emply?.IsActive);
                setEmpoyeeName(emply?.EmployeeName);
                handleShow3();
                handlePhotoUrl(emply);
              }}
              className={
                emply?.IsActive === true ? "green-active" : "red-deactive"
              }
            >
              {emply?.IsActive === true ? "Active" : "De-active"}
            </td>
            {permissions && permissions["View Employee Profile"] && (
              <td>
                &nbsp; &nbsp; &nbsp;
                <img
                  src={view}
                  className="mx-2 editBtn"
                  onClick={() => {
                    navigate("/Profile/" + emply.EmployeeId);
                  }}
                  alt=""
                />
                {permissions && permissions["Delete Employee"] && (
                  <img
                    onClick={() => {
                      handleShow4();
                      setDeleteTaskList(emply.EmployeeId);
                      //   deleteDesignation(emply.EmployeeId);
                    }}
                    src={DelectAc}
                    alt=""
                  />
                )}
              </td>
            )}
          </tr>
        );
      });

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">Employee List</h6>
          </div>
          <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
            <div className="role-table-header">
              <div className="search-input-wrapper">
                <input
                  type="text"
                  placeholder="Search Name / Work Location"
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    setCurrentPage(0);
                  }}
                />
                {Dark === "lightMode" ? (
                  <img src={searchicon} alt="" />
                ) : (
                  <img src={ic_search_dark} alt="" />
                )}
              </div>
              <div className="entries-wrapper">
                <p>Entries per page</p>
                <select
                  className="form-select select-drop-icon"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setUsersPerPage(e.target.value);
                    setCurrentPage(0);
                  }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25" selected>
                    25
                  </option>
                </select>
              </div>
              {permissions &&
                permissions["Access to Active/De-Active employees"] && (
                  <div className="entries-wrapper">
                    <p>Select User</p>
                    <select
                      className="form-select select-drop-icon"
                      aria-label="Default select example"
                      onChange={(e) => {
                        setShowDeactive(e.target.value);
                        //setCurrentPage(0);
                      }}
                    >
                      <option value="">Select</option>
                      <option value="1">Active</option>
                      <option value="2">De-Active</option>
                    </select>
                  </div>
                )}
            </div>
            <div className="role-btns-wrapper ">
              {permissions && permissions["Active Employee List Export"] && (
                <button>
                  {excelLoading ? (
                    <ExcelLoader />
                  ) : (
                    <img
                      src={ic_excel}
                      onClick={(e) => {
                        downloadExcelFile(e);
                      }}
                      alt=""
                    />
                  )}
                </button>
              )}
            </div>
          </div>
          <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
            {loading ? (
              <Loader />
            ) : EmployeeList && EmployeeList.length > 0 ? (
              <table>
                <thead>
                  <tr className="firstTabelRow">
                    <th>Sr.no</th>
                    <th>Photo</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Department</th>
                    <th>Date Of Joining</th>
                    <th
                    // onClick={handleSortByLocation}
                    >
                      Work Location
                    </th>
                    <th>Status / Action</th>
                    <th>Details/Action</th>
                  </tr>
                </thead>
                <tbody>
                  {" "}
                  {showDeactive == 2
                    ? !searchValue == ""
                      ? DeactivedisplaySearchedTaskList
                      : DeActiveUsers
                    : searchValue == ""
                    ? displayTaskList
                    : displaySearchedTaskList}
                </tbody>
              </table>
            ) : (
              <>
                <NoData />
              </>
            )}

            {/* {!loading && searchedDataPageCounts.length > usersPerPage && ( */}
            <Pagination
              data={EmployeeList}
              pageChangeHandler={pageChangeHandler}
              usersPerPage={usersPerPage}
              z
              currentPage={currentPage}
              searchValue={searchValue}
              searchedDataPageCounts={searchedDataPageCounts}
            />
            {/* )} */}
          </div>
        </div>
      </div>

      {/* Active De-active Modal Start */}
      <Modal
        className="delete-news-modal"
        show={show3}
        onHide={handleClose3}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="delete-title">Confirmation Alert</Modal.Title>
        </Modal.Header>
        <div class="lineSeperate"></div>
        <Modal.Body className="delete-body ps-4">
          <p>
            Are You Sure You Want To {isActive ? "DeActive" : "Active"}{" "}
            {empoyeeName}
            {/* {"( "+deleteProjectName + " )"} */}
          </p>
        </Modal.Body>

        <div className="lineSeperate"></div>
        <div className="d-flex justify-content-end align-items-center mainBtnsSub">
          <button className="btn-width cancelBtn mx-3" onClick={handleClose3}>
            Cancel
          </button>
          <button
            className="btn-width saveBtn"
            onClick={() => {
              //deleteProject(useDeleteTaskList)
              changeRoleStatusHandler(userId);
              handleClose3();
            }}
          >
            {isActive ? "De Active" : "Active"}
          </button>
        </div>
      </Modal>
      {/* Active De-active Modal End */}

      {/* Delete News and Blogs Modal Start */}
      <Modal
        className="delete-news-modal"
        show={show4}
        onHide={handleClose4}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="delete-title">
            Delete Confirmation
          </Modal.Title>
        </Modal.Header>
        <div class="lineSeperate"></div>
        <Modal.Body className="delete-body ps-4">
          {/* <p>Are you sure, You want to delete? 
            {/* {"( "+deleteProjectName + " )"} */}
          <p> Are you sure, You want to delete? API NOT FOUND</p>
        </Modal.Body>

        <div className="lineSeperate"></div>
        <div className="d-flex justify-content-end align-items-center mainBtnsSub">
          <button className="btn-width cancelBtn mx-3" onClick={handleClose4}>
            Cancel
          </button>
          <button
            className="btn-width saveBtn"
            onClick={() => {
              //deleteProject(useDeleteTaskList)
              deleteDesignation(DeleteTaskList);
              handleClose4();
            }}
          >
            Delete
          </button>
        </div>
      </Modal>
      {/* Delete News and Blogs Modal End */}
    </>
  );
};

export default List;
