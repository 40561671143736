import React, { useContext, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import BookIcon from "../../assets/images/ic_excel.svg";
import { Link } from "react-router-dom";
import deleteicon from "../../assets/images/DelectAc.svg";
import edit from "../../assets/images/Editac.svg";
import Pagination from "../../sharedComponent/Pagination";
import Modal from "react-bootstrap/Modal";

const IVRSetting = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const [selectedRows, setSelectedRows] = useState(10);

  const handleSelectChange = (event) => {
    setSelectedRows(event.target.value);
    console.log(`Selected number of rows: ${event.target.value}`);
  };

  const dataArrayLocation = [
    {
      id: 1,
      userlist: "User 1",
      api: "xyzabc1234",
      username: "Testbytes",
      Action: "Delete",
    },
    {
      id: 2,
      userlist: "User 2",
      api: "xyzabc1234",
      username: "Testbytess",
      Action: "Delete",
    },
  ];

  const splitToken = (token, chunkSize) => {
    const chunks = [];
    for (let i = 0, len = token.length; i < len; i += chunkSize) {
      chunks.push(token.substring(i, i + chunkSize));
    }
    return chunks.join("<br/>");
  };

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role ">
          <h6 className="mt-2">IVR Setting</h6>

          <Link
            style={{ color: "var(--blue_53B7E8_LightMode" }}
            to="/CreateIVR"
          >
            <button className="create-btn-sub-header">Add IVR</button>
          </Link>
        </div>
        <div className="d-flex justify-content-between mainHeader custom-mb mt-4">
          <div className="role-table-header-main">
            <div className="search-input-wrapper mt-0 cus-mb">
              <input type="text" placeholder="Search here" />

              {Dark === "lightMode" ? (
                <img src={searchicon} alt="" />
              ) : (
                <img src={ic_search_dark} alt="" />
              )}
            </div>
            <div className="entries-wrapper mt-0 cus-mb">
              <p>Entries per page</p>
              <select
                className="form-select select-drop-icon"
                aria-label="Default select example"
                value={selectedRows}
                onChange={handleSelectChange}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
          </div>
          <div className="d-flex justify-content-between mainIcons">
            <div className="booksIcon" style={{ textAlign: "center" }}>
              <img src={BookIcon} className="innerIconsBtn" alt="" />
            </div>
          </div>
        </div>
        <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
          <table className="">
            <thead className="firstTabelRow">
              <tr classname="row-hover modal-footer-table-body-tr">
                <th>USER LIST</th>
                <th>API</th>
                <th>USER NAME</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
              {dataArrayLocation.map((item) => (
                <tr key={item.id} className="table-row-custom">
                  <td>{item.userlist}</td>
                  <td
                    className="token-column"
                    dangerouslySetInnerHTML={{
                      __html: splitToken(item.api, 40),
                    }}
                  ></td>
                  <td className="Source">{item.username}</td>
                  <td>
                    <div className="d-flex">
                      <div onClick={handleShow4}>
                        <img
                          src={deleteicon}
                          alt="Image 2"
                          className="cell-image "
                        />
                      </div>

                      <Link to="/editIVR">
                        <img
                          src={edit}
                          alt="Image 1"
                          className="cell-image ml-2"
                        />
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination />
        </div>
      </div>
      <Modal
        className="delete-news-modal"
        show={show4}
        onHide={handleClose4}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="delete-title">
            Delete Confirmation
          </Modal.Title>
        </Modal.Header>
        <div class="lineSeperate"></div>
        <Modal.Body className="delete-body ps-4">
          <p>Are you sure you want to delete this WhatsApp ?</p>
        </Modal.Body>

        <div className="lineSeperate"></div>
        <div className="d-flex justify-content-end align-items-center mainBtnsSub">
          <button className="btn-width cancelBtn mx-3" onClick={handleClose4}>
            Cancel
          </button>
          <button
            className="btn-width saveBtn"
            onClick={() => {
              handleClose4();
            }}
          >
            Delete
          </button>
        </div>
      </Modal>
    </div>
  );
};
export default IVRSetting;
