import React, { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function UpdateIVR() {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="sub-header-role ">
          <h6 className="mt-2">IVR Update</h6>
        </div>

        <div className="WeeklyMain">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="my-3">
                <Form.Group>
                  <Form.Label className="mb-2 text-grey">
                    User List :
                  </Form.Label>
                  <Form.Select className="form-control tasKCategory1" required>
                    <option>Select</option>
                    <option>user 1</option>
                    <option>user 1</option>
                    <option>user 1</option>
                    <option>user 1</option>
                  </Form.Select>
                </Form.Group>
              </div>
            </div>

            <div className="col-lg-7">
              <div className="my-3">
                <Form.Group>
                  <Form.Label className="mb-2 text-grey">API :</Form.Label>
                  <Form.Control
                    className="form-control tasKCategory1"
                    type="text"
                  />
                </Form.Group>
              </div>
            </div>

            <div className="col-lg-7">
              <div className="my-3">
                <Form.Group>
                  <Form.Label className="mb-2 text-grey">
                    User Name :
                  </Form.Label>
                  <Form.Control
                    className="form-control tasKCategory1"
                    type="text"
                  />
                </Form.Group>
              </div>
            </div>
          </div>

          <div className="align-items-center justify-content-center d-flex mt-5 mb-3">
            <button className="allocateionBtn">
              <Link className="text-white" to="">
                Update IVR
              </Link>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
