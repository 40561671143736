import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import "../../assets/css/myAttendence.scss";
import ReactPaginate from "react-paginate";
import Laptop from "../../assets/images/laptop.svg";
import Android from "../../assets/images/android.svg";
import Apple from "../../assets/images/apple.svg";
import Wifi from "../../assets/images/wifi.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import searchicon from "../../assets/images/ic_search.svg";
import { PostCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { json } from "react-router-dom";
import moment from "moment";
import Loader from "../Loader";
import ic_excel from "../../assets/images/ic_excel.svg";
import FileSaver from "file-saver";
import ExcelLoader from "../../sharedComponent/ExcelLoader";

const DevicePunchHistory = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  const [punchHistory, setPunchHistory] = useState([]);
  const [filteredPunchHistory, setFilteredPunchHistory] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const [loading, setLoading] = useState(false);
  const [body, setBody] = useState({
    search: moment(new Date()).format("MM/DD/YYYY"),
    OrgId: Number(userData.OrgId),
  });

  // kjdsfhsdui
  // kjsdfjkg

  const [excelLoading, setExcelLoading] = useState(false);

  const downloadExcelFile = (e) => {
    setExcelLoading(true);
    let payload = {
      search: moment(new Date()).format("MM/DD/YYYY"),
    };
    e.preventDefault();
    PostCallWithErrorResponse(
      ApiConfig.PUNCH_HISTORY_EXPORT,
      JSON.stringify(payload)
    )
      .then((res) => {
        console.log("Download File Response", res);
        if (res.error) {
          console.log("Error response", res);
        } else {
          if (res.json.Success === true) {
            setExcelLoading(false);
            let data = res.json.Data;
            FileSaver.saveAs(
              "data:" + data.ContentType + ";base64," + data.FileContents,
              data.FileDownloadName
            );
          } else {
            console.log("res", res);
            setExcelLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setLoading(true);
    PostCallWithErrorResponse(ApiConfig.PUNCH_HISTORY, JSON.stringify(body))
      .then((res) => {
        console.log(res);

        if (res.json.Success === true) {
          setPunchHistory(res.json?.Data ? res.json?.Data : []);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [body]);

  useEffect(() => {
    const filteredData =
      punchHistory &&
      punchHistory?.filter((item) =>
        item.FullName.toLowerCase().includes(searchValue.toLowerCase())
      );
    setFilteredPunchHistory(filteredData);
  }, [searchValue, punchHistory]);

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content-main">
          <div className="sub-header-role">
            <h6 className="mt-2">Device Punch History</h6>
          </div>
          <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
            <div className="role-table-header">
              <div className="search-input-wrapper  mb-2 ">
                <input
                  type="text"
                  placeholder="Search Name"
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                />
                {Dark === "lightMode" ? (
                  <img src={searchicon} alt="" />
                ) : (
                  <img src={ic_search_dark} alt="" />
                )}

                <div className="ml-5">
                  <input
                    type="date"
                    data-date=""
                    data-date-format="DD MMMM YYYY"
                    placeholder="Search"
                    onChange={(e) => {
                      setBody({
                        ...body,
                        search: moment(e.target.value).format("MM/DD/YYYY"),
                      });

                      // ;
                      // setDate({
                      //   date: dataFun(e.target.value),
                      // });
                    }}
                  />
                </div>
                {/* {Dark === "lightMode" ? (
                  <img src={searchicon} alt="" />
                ) : (
                  <img src={ic_search_dark} alt="" />
                )} */}
              </div>
              <div className="import-export-btn-wrapper mt-4">
                <button style={{ marginLeft: "780px" }}>
                  {excelLoading ? (
                    <ExcelLoader />
                  ) : (
                    <img
                      src={ic_excel}
                      onClick={(e) => downloadExcelFile(e)}
                      alt=""
                    />
                  )}
                </button>
              </div>
              {/* <div className="entries-wrapper">
                <p>Entries per page</p>
                <select
                  className="form-select select-drop-icon"
                  aria-label="Default select example"
                >
                  <option selected>10</option>
                  <option value="1">20</option>
                  <option value="2">10</option>
                  <option value="3">5</option>
                </select>
              </div> */}
            </div>
          </div>

          {/* <div className='emptyPage'>
                        <img src={emptypage} alt="" />
                    </div> */}
          <div className="table-wrapper-main mb-4">
            {loading ? (
              <Loader />
            ) : (
              <>
                <table>
                  <thead>
                    <tr className="firstTabelRow">
                      <th>Sr. no</th>
                      <th>Employee Name</th>
                      <th>Check In Device</th>
                      <th>Check In Location</th>
                      <th>Check Out Device</th>
                      <th>CheckOut Location</th>
                    </tr>
                  </thead>

                  {filteredPunchHistory && filteredPunchHistory?.length > 0 ? (
                    filteredPunchHistory?.map((punch, index) => {
                      return (
                        <tbody>
                          <tr className="table-row-main" key={"4+sdf" + index}>
                            <td> {index + 1} </td>
                            <td> {punch.FullName}</td>
                            <td>
                              <div className="d-flex justify-content-between">
                                <div className="d-flex">
                                  <img
                                    src={
                                      punch.PlatFormId == "1"
                                        ? Laptop
                                        : punch.PlatFormId == "2"
                                        ? Android
                                        : punch.PlatFormId == "3"
                                        ? Apple
                                        : punch.PlatFormId == "4"
                                        ? Wifi
                                        : Wifi
                                    }
                                    alt=""
                                  />
                                  <div className="mx-2">
                                    <label htmlFor="">
                                      {punch.PlatFormId == "1" ? (
                                        <> Browser </>
                                      ) : punch.PlatFormId == "2" ? (
                                        <>Android</>
                                      ) : punch.PlatFormId == "3" ? (
                                        <>IOS</>
                                      ) : punch.PlatFormId == "4" ? (
                                        <>Wifi-Direct</>
                                      ) : (
                                        <>Wifi-Direct</>
                                      )}
                                    </label>
                                  </div>
                                  <div className="mx-1">
                                    <label htmlFor=""> {punch.InTime} </label>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="mx-1">
                                <label htmlFor="">{punch.InTimeLocation}</label>
                              </div>
                            </td>

                            <td>
                              <div className="d-flex justify-content-between">
                                <div className="d-flex">
                                  <img
                                    src={
                                      punch.OutPlatFormId == "0"
                                        ? ""
                                        : punch.OutPlatFormId == "1"
                                        ? Laptop
                                        : punch.OutPlatFormId == "2"
                                        ? Android
                                        : punch.OutPlatFormId == "3"
                                        ? Apple
                                        : punch.OutPlatFormId == "4"
                                        ? Wifi
                                        : Wifi
                                    }
                                    alt=""
                                  />
                                  <div className="mx-2">
                                    {punch.OutPlatFormId == "0" ? (
                                      ""
                                    ) : punch.OutPlatFormId == "1" ? (
                                      <> Browser </>
                                    ) : punch.OutPlatFormId == "2" ? (
                                      <>Android</>
                                    ) : punch.OutPlatFormId == "3" ? (
                                      <>IOS</>
                                    ) : punch.OutPlatFormId == "4" ? (
                                      <>Wifi-Direct</>
                                    ) : (
                                      <>Wifi-Direct</>
                                    )}
                                  </div>
                                  <div className="mx-2">
                                    <label htmlFor=""> {punch.OutTime}</label>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="mx-2">
                                <label htmlFor="">
                                  {punch.OutTimeLocation}
                                </label>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })
                  ) : (
                    <tr className="ErrorDataNotFound">
                      <td colSpan={6}>Data Not Found</td>
                    </tr>
                  )}
                </table>{" "}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DevicePunchHistory;
