import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import React, { useState, useContext, useEffect } from "react";
import "../../assets/css/tasklist.scss";
import { Button, Dropdown } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import Check from "../../assets/images/check.svg";
import Priority from "../../assets/images/priority.svg";
import searchicon from "../../assets/images/ic_search.svg";
import optionIcon from "../../assets/images/bx_edit.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import ReactPaginate from "react-paginate";
import { PostCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../Loader";
import { debounce } from "lodash";
import { ToastMsg } from "../TostMsg";
import Pagination from "../../sharedComponent/Pagination";
import { getDateddmmyyyy } from "../../common";
import BackDropLoader from "../../sharedComponent/BackDropLoader";

const TaskApprove = () => {
  const [show, setShow] = useState(false);
  const [hide, setHide] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleHide = () => setHide(false);
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);

  // States
  const [loading, setLoading] = useState(false);
  const [approveTaskList, setApproveTaskList] = useState([]);
  // States

  const [btnLoading, setBtnLoading] = useState(false);
  const approveTaskApi = (id, isApprove, btntype, innerIndex) => {
    setBtnLoading(true);
    let body = {
      TaskId: id,
      isApprove: isApprove,
    };

    PostCallWithErrorResponse(ApiConfig.TASK_APPROVE, JSON.stringify(body))
      .then((res) => {
        if (res.json.Success === true) {
          ToastMsg("success", res.json.Message);
          setBtnLoading(false);
        } else {
          ToastMsg("error", res.json.Message);
        }
        getApprovedTaskList();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setBtnLoading(false);
      });
  };

  useEffect(() => {
    getApprovedTaskList();
  }, []);

  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(25);

  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };

  const searchedDataPageCounts =
    approveTaskList &&
    approveTaskList.length &&
    approveTaskList.filter((item) =>
      item.EmployeeName?.toLowerCase().includes(searchValue.toLowerCase())
    );

  const pageCount = Math.ceil(approveTaskList?.length / usersPerPage);
  const searchedPageCounts = Math.ceil(
    searchedDataPageCounts?.length / usersPerPage
  );

  // GET_TASK_APPROVE_LIST...
  const getApprovedTaskList = () => {
    setLoading(true);
    let payLoad = {
      EmployeeId: Number(userData.EmpId),
    };
    PostCallWithErrorResponse(
      ApiConfig.GET_TASK_APPROVE_LIST,
      JSON.stringify(payLoad)
    ).then((res) => {
      setLoading(false);
      setApproveTaskList(res.json.Data);
    });
  };

  const btnLoader = (
    <div
      class="spinner-border text-info"
      role="status"
      style={{
        width: "0.8rem",
        height: "0.8rem",
        position: "static",
        marginRight: "33.5px",
        marginLeft: "33.5px",
        marginTop: "8px",
      }}
    ></div>
  );

  const rejectBtnLoader = (
    <div
      class="spinner-border text-info"
      role="status"
      style={{
        width: "0.8rem",
        height: "0.8rem",
        position: "static",
        marginRight: "14.5px",
        marginLeft: "28.5px",
        marginTop: "8px",
      }}
    ></div>
  );

  const displayData =
    approveTaskList &&
    approveTaskList.slice(startIndex, endIndex).map((item, innerIndex) => {
      return (
        <tr key={"approveTaskList" + innerIndex} className="table-row-custom">
          <td id="sr-no-padding">{startIndex + innerIndex + 1}</td>
          <td>{item.EmployeeName}</td>
          <td>{item.ProjectName}</td>
          <td className="break-ln-spaces">{item.TaskName}</td>
          <td></td>
          <td>{item?.StartDate && getDateddmmyyyy(item.StartDate)}</td>
          <td>{item?.EndDate && getDateddmmyyyy(item.EndDate)}</td>
          <td>{item.BudgetedHours}</td>
          <td className="custom-padding-for-btn">
            {/* {btnLoading.approveLoading && btnLoading.index === innerIndex ? (
              btnLoader
            ) : (  */}
            <button
              type="button"
              disabled={btnLoading ? true : false}
              className="btn-green"
              onClick={(e) =>
                approveTaskApi(item.TaskId, true, "approve", innerIndex)
              }
            >
              Approve
            </button>
            {/* )}*/}

            {/*  {btnLoading.rejectLoading && btnLoading.index === innerIndex ? (
              rejectBtnLoader
            ) : ( */}
            <button
              type="button"
              disabled={btnLoading ? true : false}
              className="btn-red"
              onClick={(e) =>
                approveTaskApi(item.TaskId, false, "reject", innerIndex)
              }
            >
              Reject
            </button>
            {/*   )}*/}
          </td>
        </tr>
      );
    });

  const displaySearchedData =
    approveTaskList &&
    approveTaskList
      ?.filter((item) =>
        item?.EmployeeName.toLowerCase().includes(searchValue.toLowerCase())
      )
      .slice(startIndex, endIndex)
      .map((item, innerIndex) => {
        return (
          <tr key={innerIndex} className="table-row-custom">
            <td id="sr-no-padding">{startIndex + innerIndex + 1}</td>
            <td>{item.EmployeeName}</td>
            <td>{item.ProjectName}</td>
            <td className="break-ln-spaces">{item.TaskName}</td>
            <td></td>
            <td>{item?.StartDate && getDateddmmyyyy(item.StartDate)}</td>
            <td>{item?.EndDate && getDateddmmyyyy(item.EndDate)}</td>
            <td>{item.BudgetedHours}</td>
            <td className="custom-padding-for-btn">
              {btnLoading.approveLoading && btnLoading.index === innerIndex ? (
                btnLoader
              ) : (
                <button
                  className="btn-green"
                  onClick={(e) =>
                    approveTaskApi(item.TaskId, true, "approve", innerIndex)
                  }
                >
                  Approve
                </button>
              )}

              {btnLoading.rejectLoading && btnLoading.index === innerIndex ? (
                rejectBtnLoader
              ) : (
                <button
                  className="btn-red"
                  onClick={(e) =>
                    approveTaskApi(item.TaskId, false, "reject", innerIndex)
                  }
                >
                  Reject
                </button>
              )}
            </td>
          </tr>
        );
      });

  return (
    <>
      {btnLoading && <BackDropLoader />}
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">Task Approval </h6>
          </div>
          <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
            <div className="role-table-header">
              <div className="search-input-wrapper">
                <input
                  type="text"
                  placeholder="Search Employee Name"
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    setCurrentPage(0);
                  }}
                />
                {Dark === "lightMode" ? (
                  <img src={searchicon} alt="" />
                ) : (
                  <img src={ic_search_dark} alt="" />
                )}
              </div>
              <div className="entries-wrapper">
                <p>Entries per page</p>
                <select
                  className="form-select select-drop-icon"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setUsersPerPage(e.target.value);
                    setCurrentPage(0);
                  }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25" selected>
                    25
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div className="table-wrapper-main" id="table-wrapper-af">
            {loading ? (
              <div style={{ marginTop: "150px" }}>
                <Loader />
              </div>
            ) : approveTaskList && searchedDataPageCounts.length > 0 ? (
              <table>
                <tr className="firstTabelRow">
                  <th>Sr. no</th>
                  <th>Employee Name</th>
                  <th>Project Name</th>
                  <th>Task Name</th>
                  <th>Activity</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Budget Hrs</th>
                  <th className="pl-7">Action</th>
                </tr>
                {searchValue === "" ? displayData : displaySearchedData}
              </table>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  color: "red",
                }}
              >
                Data Not Found
              </div>
            )}
          </div>
          {!loading && searchedDataPageCounts.length > usersPerPage && (
            <Pagination
              data={approveTaskList}
              pageChangeHandler={pageChangeHandler}
              usersPerPage={usersPerPage}
              currentPage={currentPage}
              searchValue={searchValue}
              searchedDataPageCounts={searchedDataPageCounts}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default TaskApprove;
