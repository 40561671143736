import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import optionIcon from "../../assets/images/bx_edit.svg";
import "../../assets/css/myAttendence.scss";
import ReactPaginate from "react-paginate";
import shareIcon from "../../assets/images/ic_export1.svg";
import BookIcon from "../../assets/images/ic_excel.svg";
import { PostCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../Loader";
import ExcelLoader from "../../sharedComponent/ExcelLoader";
import FileSaver from "file-saver";
import {
  convertTo12HourFormat,
  getDateddmmyyyy,
  formatTime,
} from "../../common";

const EmployeeAttendenceReport = () => {
  const { sidebar, setSidebar, Dark, setDark, userData, permissions } =
    useContext(AppContext);
  const [empoleName, setEmpoleName] = useState([]);
  const [employDetails, setEmployDetails] = useState([]);
  const [attendanceReport, setAttendanceReport] = useState({});
  const [attendanceReportList, setAttendanceReportList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [excelLoading, setExcelLoading] = useState(false);
  const [employeeListObj, setEmployeeListObj] = useState({
    orgId: Number(userData.OrgId),
    Month: new Date().getMonth() + 1,
    Year: new Date().getFullYear(),
    EmpId: Number(userData.EmpId),
  });

  // Split shifttime as starttime and endtime
  let shiftStartTime =
    attendanceReport &&
    attendanceReport?.ShiftTime &&
    attendanceReport?.ShiftTime?.split("-")[0];

  let shiftEndTime =
    attendanceReport &&
    attendanceReport?.ShiftTime &&
    attendanceReport?.ShiftTime?.split("-")[1];

  const minOffset = 0;
  const maxOffset = 1;
  let crryear = new Date().getFullYear();
  let allYears = [];
  for (let x = 0; x <= maxOffset; x++) {
    allYears.push(crryear - x);
  }

  useEffect(() => {
    getEmployeeNameList();
    getAttendanceReport();
  }, [employeeListObj]);

  const getAttendanceReport = () => {
    setLoader(true);
    PostCallWithErrorResponse(
      ApiConfig.ATTENDANCE_REPORT,
      JSON.stringify({ ...employeeListObj })
    )
      .then((res) => {
        console.log("res,", res);
        if (res.json.Success === true) {
          setAttendanceReport(res.json.Data);
          setEmployDetails(
            res.json.Data?.EmployeeDetailsViews[0]
              ? res.json.Data?.EmployeeDetailsViews[0]
              : null
          );
          console.log(res.json?.Data.AttendanceReportList);
          setAttendanceReportList(res.json?.Data.AttendanceReportList);
          setLoader(false);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  let setfilter = attendanceReportList.filter((i) => i.Intime === "1");

  useEffect(() => {
    // getEmployeeNameList()
  }, []);

  const getEmployeeNameList = () => {
    setLoading(true);
    PostCallWithErrorResponse(
      ApiConfig.EMPLOEE_NAME_LIST,
      JSON.stringify({ OrgId: Number(userData.OrgId) })
    )
      .then((res) => {
        if (res.json.Success === true) {
          setLoading(false);
          setEmpoleName(res.json.Data);
        } else {
          setEmpoleName([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const downLoadExcelSheet = () => {
    setExcelLoading(true);
    PostCallWithErrorResponse(
      ApiConfig.ATTENDANCE_REPORT,
      JSON.stringify({ ...employeeListObj, ExportToExcel: true })
    )
      .then((res) => {
        if (res.error) {
          console.log("Error response", res);
        } else {
          if (res.json.Success === true) {
            setExcelLoading(false);
            let data = res.json.Data;
            console.log("data", data);
            FileSaver.saveAs(
              "data:" + data.ContentType + ";base64," + data.FileContents,
              data.FileDownloadName
            );
          } else {
            console.log("res", res);
            setExcelLoading(false);
          }
        }
      })
      .catch((err) => {
        setExcelLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content-main">
          <div className="sub-header-role mb-4">
            <h6 className="mt-2">Employee Attendance Report</h6>
          </div>
          <div className="d-flex justify-content-between mb-4 mainHeader">
            <div className="role-table-header-main">
              <div className="search-input-wrapper-main">
                <select
                  className="form-select input-select-option"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setEmployeeListObj({
                      ...employeeListObj,
                      EmpId: Number(e.target.value),
                    });
                  }}
                  value={employeeListObj.EmpId}
                >
                  <option value="">Select Employee Name</option>
                  {empoleName &&
                    empoleName?.map((emp) => {
                      return <option value={emp.Id}>{emp.FullName}</option>;
                    })}
                </select>
              </div>
              <div className="search-input-wrapper-main mx-4">
                <select
                  className="form-select input-select-option"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setEmployeeListObj({
                      ...employeeListObj,
                      Year: Number(e.target.value),
                    });
                  }}
                  value={employeeListObj.Year}
                >
                  {/* <option value="">Select Year</option> */}
                  {allYears.map((year, i) => {
                    return (
                      <option key={i} value={year}>
                        {year}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="search-input-wrapper-main">
                <select
                  className="form-select input-select-option"
                  onChange={(e) => {
                    setEmployeeListObj({
                      ...employeeListObj,
                      Month: Number(e.target.value),
                    });
                  }}
                  value={employeeListObj.Month}
                >
                  {/* <option>Select Month</option> */}
                  <option value="1">Janaury</option>
                  <option value="2">February</option>
                  <option value="3">March</option>
                  <option value="4">April</option>
                  <option value="5">May</option>
                  <option value="6">June</option>
                  <option value="7">July</option>
                  <option value="8">August</option>
                  <option value="9">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
              </div>
            </div>
            <div className="d-flex justify-content-between mainIcons">
              {/* <div className="shareIcon mx-3">
                <img src={shareIcon} className="innerIconsBtn" alt="" />
              </div> */}
              {permissions &&
                permissions["Employee Attendance Report Export"] && (
                  <div className="booksIcon" style={{ textAlign: "center" }}>
                    {excelLoading ? (
                      <ExcelLoader />
                    ) : (
                      <img
                        src={BookIcon}
                        className="innerIconsBtn"
                        alt=""
                        onClick={(e) => {
                          downLoadExcelSheet();
                        }}
                      />
                    )}
                  </div>
                )}
            </div>
          </div>
          {loader ? (
            <Loader />
          ) : (
            <>
              <div className="empolyDetails mb-4">
                <div className="employeeDet">
                  <div className="mb-2">
                    <p className="detailHead">Employee Details</p>
                    <div className="middleLine mt-1"></div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 col-md-4 col-sm-6 responsiveEmpolydatda mb-3">
                      <label className="detailLabel">Employee Name</label>
                      <p className="detailText">
                        {employDetails?.FirstName
                          ? `${employDetails?.FirstName} ${
                              employDetails?.LastName
                                ? employDetails?.LastName
                                : ""
                            }`
                          : ""}{" "}
                      </p>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 responsiveEmpolydatda mb-3">
                      <label className="detailLabel">
                        Employee Designation
                      </label>
                      <p className="detailText">
                        {" "}
                        {employDetails?.DesignationName
                          ? employDetails?.DesignationName
                          : ""}{" "}
                      </p>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 responsiveEmpolydatda mb-3">
                      <label className="detailLabel">Department</label>
                      <p className="detailText">
                        {" "}
                        {employDetails?.DepartmentName
                          ? employDetails?.DepartmentName
                          : ""}
                      </p>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 responsiveEmpolydatda mb-3">
                      <label className="detailLabel">Shift Timing</label>
                      <p className="detailText">
                        {shiftStartTime &&
                          convertTo12HourFormat(shiftStartTime).substring(
                            0,
                            5
                          ) &&
                          convertTo12HourFormat(shiftStartTime).substring(0, 5)}
                        {" AM "}-{" "}
                        {shiftEndTime &&
                          convertTo12HourFormat(shiftEndTime).substring(0, 5) &&
                          convertTo12HourFormat(shiftEndTime).substring(0, 4)}
                        {" PM "}
                        {/*{attendanceReport?.ShiftTime
                          ? attendanceReport?.ShiftTime
                          : ""}{" "} */}
                      </p>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 responsiveEmpolydatda mb-3">
                      <label className="detailLabel">Total Working Days</label>
                      <p className="detailText">
                        {attendanceReport?.WorkingDays
                          ? attendanceReport?.WorkingDays
                          : ""}{" "}
                      </p>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 responsiveEmpolydatda mb-3">
                      <label className="detailLabel">Total Days Present</label>
                      <p className="detailText">
                        {" "}
                        {attendanceReport?.WorkedDays
                          ? attendanceReport?.WorkedDays
                          : "0"}
                      </p>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 responsiveEmpolydatda mb-3">
                      <label className="detailLabel">Total Days Absent</label>
                      <p className="detailText">
                        {attendanceReport?.DaysAbsent
                          ? attendanceReport?.DaysAbsent
                          : 0}{" "}
                      </p>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 responsiveEmpolydatda mb-3">
                      <label className="detailLabel">
                        Total Leaves Availed
                      </label>
                      <p className="detailText">
                        {" "}
                        {attendanceReport?.LeavesAvailed
                          ? attendanceReport?.LeavesAvailed
                          : 0}
                      </p>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 responsiveEmpolydatda mb-3">
                      <label className="detailLabel">Total Miss Punch</label>
                      <p className="detailText">
                        {attendanceReport?.MissPunch
                          ? attendanceReport?.MissPunch
                          : 0}{" "}
                      </p>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 responsiveEmpolydatda mb-3">
                      <label className="detailLabel">
                        Total Early Check-out
                      </label>
                      <p className="detailText">
                        {" "}
                        {attendanceReport?.EarlyOut
                          ? attendanceReport?.EarlyOut
                          : 0}
                      </p>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 responsiveEmpolydatda mb-3">
                      <label className="detailLabel">
                        Total Late Check-In's
                      </label>
                      <p className="detailText">
                        {" "}
                        {attendanceReport?.LatePunch
                          ? attendanceReport?.LatePunch
                          : 0}
                      </p>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 responsiveEmpolydatda mb-3">
                      <label className="detailLabel">Date Of Joining</label>
                      <p className="detailText">
                        {" "}
                        {attendanceReport?.DateOfJoining
                          ? getDateddmmyyyy(attendanceReport?.DateOfJoining)
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="table-wrapper-main mb-4">
                {attendanceReportList && attendanceReportList.length < 0 ? (
                  <Loader />
                ) : (
                  <table>
                    <thead>
                      <tr className="firstTabelRow">
                        <th>Sr.No</th>
                        <th>Date</th>
                        <th>Check In</th>
                        <th>Check Out</th>
                        <th>Remarks</th>
                      </tr>
                    </thead>
                    <tbody>
                      {attendanceReportList &&
                      attendanceReportList.length > 0 ? (
                        attendanceReportList.map((report, index) => {
                          return (
                            <tr
                              className="table-row-main"
                              key={index + "report"}
                            >
                              <td> {index + 1}</td>
                              <td>{getDateddmmyyyy(report.date)} </td>
                              <td>
                                {formatTime(report.Intime).toUpperCase()}{" "}
                              </td>
                              <td>
                                {formatTime(report.OutTime).toUpperCase()}{" "}
                              </td>

                              <td
                                className={
                                  report.Remark === "Absent"
                                    ? "status-label-danger"
                                    : report.Remark === "Present" ||
                                      report.Remark === "Present Day"
                                    ? "status-label-green"
                                    : report.Remark === "Weekend" ||
                                      report.Remark === "Off Day"
                                    ? "status-grey"
                                    : report.Remark === "On Leave" ||
                                      report.Remark === "Half Day Leave" ||
                                      report.Remark === "Holiday"
                                    ? "status-h-leave"
                                    : report.Remark === "Late Punch In" ||
                                      report.Remark === "Early Punch Out"
                                    ? "status-label-grey"
                                    : "status-label-grey"
                                }
                              >
                                {report.Remark}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="ErrorDataNotFound">
                          <td colSpan={6}>Data Not Found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default EmployeeAttendenceReport;
