import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { Button, Form } from "react-bootstrap";
import {
  PostCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { ToastMsg } from "../TostMsg";
import ButtonLoader from "../../sharedComponent/ButtonLoader";

function LeaveSetting() {
  const { sidebar } = useContext(AppContext);

  const initialState = {
    LeaveTypesId: "",
    IsPaid: true,
    Gender: "",
    MaritalStatus: "",
    CanTakeHalfDay: true,
    InitialValueDuringProbation: "",
    ExcludeHolidays: true,
    ExcludeOffdays: true,
    IncludeHolidaysOffdaysAfterdays: "",
    MaximumNoConsecutiveLeaveAllowed: "",
    LeaveApplicatonBeforeDays: "",
    CarryForwardLeaveCount: "",
    IsYearly: true,
    AccrualPeriodType: "",
    AccrualPeriod: "st",
    MaximumAccrualYearlyLeave: "",
    Leaves: "",
    BackdateAllowed: true,
    MaxLeaveMonthly: "",
    ProbationPeriod: "",
    AllowedInProbation: true,
  };

  const [valiteded, setValiteded] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [leaveSetting, setLeaveSetting] = useState(initialState);

  const [leaveTypes, setLeaveTypes] = useState([]);

  const getLeavesTypes = () => {
    postWithAuthCallWithErrorResponse(ApiConfig.GET_LEAVE_TYPE)
      .then((res) => {
        if (res?.json?.Success) {
          setLeaveTypes(res?.json?.Data);
        } else {
          console.log("something went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getLeavesTypes();
  }, []);

  const handleChange = (key, val) => {
    setLeaveSetting((prev) => ({
      ...prev,
      [key]: val,
    }));
  };

  const getLeaveSetting = (id) => {
    handleChange("LeaveTypesId", id);

    simpleGetCallWithErrorResponse(ApiConfig.GET_LEAVE_SETTING + id)
      .then((res) => {
        if (res?.json?.Success) {
          if (res?.json?.Data) {
            setLeaveSetting(res?.json?.Data);
          }
        } else {
          ToastMsg("error", res?.json?.message);
        }
      })
      .catch((err) => {
        ToastMsg("error", "somethinq Went wrong");
      });
  };

  const addUpdateLeaveSetting = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      setIsLoading(true);
      PostCallWithErrorResponse(
        ApiConfig.CREATE_AND_UPDATE_LEAVE_SETTING,
        JSON.stringify(leaveSetting)
      )
        .then((res) => {
          if (res?.json?.Success) {
            ToastMsg("success", res?.json?.Message);
          } else {
            ToastMsg("error", res?.json?.Message);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          ToastMsg("error", "Something Went Wrong Try Again");
          setIsLoading(false);
        });
    }

    setValiteded(true);
  };

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">Leave Settings</h6>
          </div>
          <div className="innerRoleSec">
            <Form
              noValidate
              validated={valiteded}
              onSubmit={addUpdateLeaveSetting}
            >
              <div className="row">
                <Form.Group className="col-md-6 mb-2">
                  <Form.Label className="mb-2 enter-role-text" style={{ fontSize: "14px" }}>
                    Leave Type :
                  </Form.Label>
                  <Form.Select
                    className="form-control tasKCategory1"
                    value={leaveSetting.LeaveTypesId}
                    onChange={(e) => getLeaveSetting(e.target.value)}
                    required
                  >
                    <option disabled value={""}>
                      Select{" "}
                    </option>
                    {leaveTypes.map((leave) => {
                      return (
                        <option value={leave?.LeaveTypeId}>
                          {leave?.LeaveTypeName}
                        </option>
                      );
                    })}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please Select Leave Type.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="col-md-6 mt-6">
                  <Form.Check.Input
                    checked={leaveSetting?.IsPaid}
                    onChange={(e) => handleChange("IsPaid", e.target.checked)}
                  ></Form.Check.Input>
                  <Form.Label className="ms-2 " style={{fontSize:"14px"}}>Is paid</Form.Label>
                </Form.Group>

                <Form.Group className="col-md-6 my-3">
                  <Form.Label className="mb-2 " style={{fontSize:"14px"}}>
                    Maximum Number Of Consective Days Of Leaved Allowed :
                  </Form.Label>
                  <Form.Control
                    className="date-label-input tasKCategory1"
                    style={{
                      fontSize: "14px",
                      height: "38px",
                      border: "1px solid #ced4da",
                    }}
                    required
                    type="number"
                    onChange={(e) =>
                      handleChange(
                        "MaximumNoConsecutiveLeaveAllowed",
                        e.target.value.length > 0 ? Number(e.target.value) : ""
                      )
                    }
                    value={leaveSetting?.MaximumNoConsecutiveLeaveAllowed}
                  />

                  <Form.Control.Feedback type="invalid">
                    Please Fill.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="col-md-6 my-3">
                  <Form.Label className="" style={{fontSize:"14px"}}>
                    Max. Leaves allowed in a month
                  </Form.Label>
                  <Form.Control
                    className="tasKCategory1"
                    style={{
                      fontSize: "14px",
                      height: "38px",
                      border: "1px solid #ced4da",
                    }}
                    required
                    type="number"
                    onChange={(e) =>
                      handleChange(
                        "MaxLeaveMonthly",
                        e.target.value.length > 0 ? Number(e.target.value) : ""
                      )
                    }
                    value={leaveSetting?.MaxLeaveMonthly}
                  ></Form.Control>

                  <Form.Control.Feedback type="invalid">
                    Please Fill
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="col-md-6 my-auto">
                  <Form.Label className="mb-2 " style={{fontSize:"14px"}}>Gender :</Form.Label>
                  <Form.Select
                    className="form-control tasKCategory1"
                    required
                    value={leaveSetting?.Gender}
                    onChange={(e) => handleChange("Gender", e.target.value)}
                  >
                    <option disabled value={""}>
                      Select Gender
                    </option>
                    <option value={"1"}>Male</option>
                    <option value={"2"}>Female</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please Select
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="col-md-6 my-3">
                  <Form.Label className="mb-2" style={{fontSize:"14px"}}>
                    Martial status :
                  </Form.Label>
                  <Form.Select
                    className="form-control tasKCategory1"
                    required
                    value={leaveSetting?.MaritalStatus}
                    onChange={(e) =>
                      handleChange("MaritalStatus", e.target.value)
                    }
                  >
                    <option disabled value="">
                      Select Martial Status
                    </option>
                    <option value={"1"}>Married</option>
                    <option value={"2"}>Unmarried</option>
                  </Form.Select>

                  <Form.Control.Feedback type="invalid">
                    Please Select
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="col-md-6 my-3">
                  <Form.Check.Input
                    checked={leaveSetting?.BackdateAllowed}
                    onChange={(e) =>
                      handleChange("BackdateAllowed", e.target.checked)
                    }
                  ></Form.Check.Input>
                  <Form.Label className="ms-2" style={{fontSize:"14px"}}>
                    BackDate Leave Application Allowed
                  </Form.Label>
                </Form.Group>

                <Form.Group className="col-md-6 my-3">
                  <Form.Check.Input
                    checked={leaveSetting?.CanTakeHalfDay}
                    onChange={(e) =>
                      handleChange("CanTakeHalfDay", e.target.checked)
                    }
                  ></Form.Check.Input>
                  <Form.Label className="ms-2" style={{fontSize:"14px"}}>
                    Provide half day option for this leave type
                  </Form.Label>
                </Form.Group>

                <Form.Group className="col-md-6 my-auto">
                  <Form.Label className="" style={{fontSize:"14px"}}>
                    No. of days before which the leave application should be
                    submitted
                  </Form.Label>
                  <Form.Control
                    className="tasKCategory1"
                    style={{
                      fontSize: "14px",
                      height: "38px",
                      border: "1px solid #ced4da",
                    }}
                    required
                    type="number"
                    value={leaveSetting?.LeaveApplicatonBeforeDays}
                    onChange={(e) =>
                      handleChange(
                        "LeaveApplicatonBeforeDays",
                        e.target.value.length > 0 ? Number(e.target.value) : ""
                      )
                    }
                  ></Form.Control>

                  <Form.Control.Feedback type="invalid">
                    Please Fill
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="col-md-6 my-3 ">
                  <Form.Check.Input
                    checked={leaveSetting?.ExcludeHolidays}
                    onChange={(e) =>
                      handleChange("ExcludeHolidays", e.target.checked)
                    }
                  ></Form.Check.Input>
                  <Form.Label className="ms-2" style={{fontSize:"14px"}}>
                    Exclude festive holidays for leave
                  </Form.Label>
                </Form.Group>

                <Form.Group className="col-md-6 my-3">
                  <Form.Label className="" style={{fontSize:"14px"}}>
                    Carry forward leave count
                  </Form.Label>
                  <Form.Control
                    className="tasKCategory1"
                    style={{
                      fontSize: "14px",
                      height: "38px",
                      border: "1px solid #ced4da",
                    }}
                    required
                    type="number"
                    value={leaveSetting?.CarryForwardLeaveCount}
                    onChange={(e) =>
                      handleChange(
                        "CarryForwardLeaveCount",
                        e.target.value.length > 0 ? Number(e.target.value) : ""
                      )
                    }
                  ></Form.Control>

                  <Form.Control.Feedback type="invalid">
                    Please Fill
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="col-md-6 my-3">
                  <Form.Check.Input
                    checked={leaveSetting?.ExcludeOffdays}
                    onChange={(e) =>
                      handleChange("ExcludeOffdays", e.target.checked)
                    }
                  ></Form.Check.Input>
                  <Form.Label className="ms-2 " style={{fontSize:"14px"}}>
                    Exclude weekends for leave
                  </Form.Label>
                </Form.Group>

                <Form.Group className="col-md-6 my-auto">
                  <Form.Label className="" style={{fontSize:"14px"}}>
                    Max. leaves approval for a year
                  </Form.Label>
                  <Form.Control
                    className="tasKCategory1"
                    style={{
                      fontSize: "14px",
                      height: "38px",
                      border: "1px solid #ced4da",
                    }}
                    required
                    type="number"
                    value={leaveSetting?.MaximumAccrualYearlyLeave}
                    onChange={(e) =>
                      handleChange(
                        "MaximumAccrualYearlyLeave",
                        e.target.value.length > 0 ? Number(e.target.value) : ""
                      )
                    }
                  ></Form.Control>

                  <Form.Control.Feedback type="invalid">
                    Please Fill
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="col-md-6">
                  <Form.Label className="" style={{fontSize:"14px"}}>Leave in a year</Form.Label>
                  <Form.Control
                    className="tasKCategory1 "
                    style={{
                      fontSize: "14px",
                      height: "38px",
                      border: "1px solid #ced4da",
                      width:"70%"
                    }}
                    required
                    type="number"
                    value={leaveSetting?.Leaves}
                    onChange={(e) =>
                      handleChange(
                        "Leaves",
                        e.target.value.length > 0 ? Number(e.target.value) : ""
                      )
                    }
                  ></Form.Control>

                  <Form.Control.Feedback type="invalid">
                    Please Fill
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="col-md-6 my-3">
                  <Form.Label className="" style={{fontSize:"14px"}}>
                    No. of consecutive days after which weekends or holidays
                    must be included
                  </Form.Label>
                  <Form.Control
                    className="tasKCategory1"
                    style={{
                      fontSize: "14px",
                      height: "38px",
                      border: "1px solid #ced4da",
                    }}
                    required
                    type="number"
                    value={leaveSetting?.IncludeHolidaysOffdaysAfterdays}
                    onChange={(e) =>
                      handleChange(
                        "IncludeHolidaysOffdaysAfterdays",
                        e.target.value.length > 0 ? Number(e.target.value) : ""
                      )
                    }
                  ></Form.Control>

                  <Form.Control.Feedback type="invalid">
                    Please Fill
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="my-3 d-flex justify-content-end">
                <button
                  className="allocateionBtn"
                  style={{ width: "100px" }}
                  disabled={isLoading}
                >
                  {isLoading ? <ButtonLoader /> : "Submit"}
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

export default LeaveSetting;
