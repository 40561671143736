import React, { useState, useContext, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import { Link, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import optionIcon from "../../assets/images/bx_edit.svg";
import "../../assets/css/main.scss";
import member_img from "../../assets/images/member_img.svg";
import profilePicBG from "../../assets/images/profilepicBG.png";
import fileUpload from "../../assets/images/fileUpload.png";
import Modal from "react-bootstrap/Modal";
import {
  multipartPostCallWithErrorResponse,
  PostCallWithErrorResponse,
  putMultipartWithAuthCall,
  putWithAuthCall,
  simpleGetCallWithErrorResponse,
  simpleGetCallWithToken,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { ToastMsg } from "../TostMsg";
import Form from "react-bootstrap/Form";
import BackDropLoader from "../../sharedComponent/BackDropLoader";
import ButtonLoader from "../../sharedComponent/ButtonLoader";
import { getDateddmmyyyy } from "../../common";

const Profile = () => {
  const {
    sidebar,
    setSidebar,
    Dark,
    setDark,
    userData,
    setHeaderProfile,
    permissions,
  } = useContext(AppContext);
  const [validated, setValidated] = useState(false);
  const [validation, setValidation] = useState(false);
  const currentRoute = useLocation().pathname;
  const [show, setShow] = useState(false);
  const [openProfessionalDetails, setOpenPrefessionalDetails] = useState(false);
  const [workDetails, setWorkDetails] = useState({});
  const handleClose = () => setShow(false);
  const params = useParams();
  let id = params.id;
  console.log(id);
  const handleShow = () => setShow(true);
  const [profileData, setProfileData] = useState([]);
  const [professionalDtlsValidation, setProfessionalDtlsValidation] =
    useState(false);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [skill, setSkill] = useState("");
  const [profile, setProfile] = useState({
    firstName: "",
    FatherName: "",
    BloodGroup: "",
    Nationality: "",
    MiddleName: "",
    MotherName: "",
    IdentificationMark: "",
    Religion: "",
    LastName: "",
    DateOfBirth: "",
    EmployeeId: "",
    OrgId: "",
    Gender: "",
    birthPlace: "",
    Maritalstatus: "",
    PhotoPath: "",
    skillSet: [],
    qualification: "",
  });

  const [professionalDtls, setProfessionalDtls] = useState({
    DepartmentId: "",
    DesignationId: "",
    LocationId: "",
    ShiftId: "",
    NatureOfPost: "",
    OrgId: "",
    EmployeeId: "",
    TeamId: "",
    ReportingTo: "",
  });

  console.log(
    "profile Img--->",
    ApiConfig.BASE_URL_FOR_IMAGES + profileData.PhotoPath
  );

  const handleProfessionalDtls = (key, val) => {
    setProfessionalDtls((prev) => ({
      ...prev,
      [key]: val,
    }));
  };

  const [cotactEdit, setCotactEdit] = useState({
    ContactId: profile.ContactId,
    EmployeeId: Number(userData.EmpId),
    Address: "",
    City: "",
    Pincode: "",
    State: "",
    PermanentAddress: "",
    Country: "",
    HomeTelephone: "",
    MobileNo: "",
    EmergencyContact: "",
    WorkTelephone: "",
    Extension: "+91",
    WorkEmail: "",
    OtherEmail: "",
    EntryBy: Number(userData.EmpId),
    EntryDate: "",
    OrgId: 0,
    EmergencyPerson: "",
    EmployeeCode: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getProfileApi();
    console.log(id);

    console.log("location--->", professionalDtls.LocationId);
  }, []);

  const getProfileApi = () => {
    console.log(id);
    const body = {
      EmpId: id ? Number(id) : Number(userData.EmpId),
      OrgId: Number(userData.OrgId),
    };
    setLoading(true);
    PostCallWithErrorResponse(ApiConfig.GET_PROFILE, JSON.stringify(body))
      .then((res) => {
        setLoading(false);
        if (res.json.Success === true) {
          console.log(res);
          let profileData = res.json?.Data[0];
          setProfileData(res.json?.Data[0] ? res.json?.Data[0] : []);

          setProfessionalDtls({
            DepartmentId: profileData?.DepartmentId,
            DesignationId: profileData?.DesignationId,
            LocationId: Number(profileData?.LocationId),
            ShiftId: Number(profileData?.ShiftId),
            NatureOfPost: profileData?.NatureOfPost,
            OrgId: profileData?.OrgId,
            EmployeeId: profileData?.EmployeeId,
            TeamId: profileData?.TeamId,
            ReportingTo: profileData?.ReportingTo,
          });

          console.log("Loaction ID--->", profileData?.LocationId);
          setCotactEdit({
            ContactId: profileData.ContactId,
            EmployeeId: profileData.EmployeeId,
            Address: profileData.Address,
            City: profileData.City,
            State: profileData.State,
            Pincode: profileData.Pincode,
            PermanentAddress: profileData.PermanentAddress,
            Country: profileData.Country,
            HomeTelephone: profileData.HomeTelephone,
            MobileNo: profileData.MobileNo,
            EmergencyContact: profileData.EmergencyContact,
            WorkTelephone: profileData.WorkTelephone,
            Extension: profileData.Extension,
            WorkEmail: profileData.WorkEmail,
            OtherEmail: profileData.OtherEmail,
            EntryBy: profileData.EmployeeId,
            EntryDate: new Date(),
            OrgId: profileData.OrgId,
            EmergencyPerson: profileData?.EmergencyPerson
              ? profileData.EmergencyPerson
              : "",
          });

          console.log(cotactEdit);

          setProfile({
            firstName: profileData.FirstName,
            FatherName: profileData.FatherName,
            BloodGroup: profileData.BloodGroup,
            Nationality: profileData.Nationality,
            MiddleName: profileData.MiddleName,
            MotherName: profileData.MotherName,
            IdentificationMark: profileData.IdentificationMark,
            Religion: profileData.Religion,
            LastName: profileData.LastName,
            DateOfBirth: profileData.DOB,
            EmployeeId: profileData.EmployeeId,
            OrgId: profileData.OrgId,
            Gender: profileData.Gender,
            birthPlace: profileData.BirthPlace,
            Maritalstatus: profileData.MaritalStatus,
            PhotoPath: profileData.PhotoPath,
            qualification: profileData?.Qualification,
            skillSet: profileData?.SkillSet
              ? JSON.parse(profileData?.SkillSet)
              : [],
          });

          setHeaderProfile({
            headerProfile: profileData.PhotoPath,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const [departmentlist, setDepartmentlist] = useState([]);
  const [designationsname, setDesignationsname] = useState([]);
  const [role, setRole] = useState([]);
  const [employeeattlist, setEmployeeattlist] = useState([]);
  const [location, setLocation] = useState([]);
  const [shift, setShift] = useState([]);

  const updatePersonalApi = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      if (profile?.skillSet.length === 0) {
        ToastMsg("error", "Please Add Skill Set");
        return;
      }

      let fromdata = new FormData();
      fromdata.append("FirstName", profile.firstName);
      fromdata.append("FatherName", profile.FatherName);
      fromdata.append("BloodGroup", profile.BloodGroup);
      fromdata.append("Nationality", profile.Nationality);
      fromdata.append("MiddleName", profile.MiddleName);
      fromdata.append("MotherName", profile.MotherName);
      fromdata.append("IdentificationMark", profile.IdentificationMark);
      fromdata.append("Religion", profile.Religion);
      fromdata.append("LastName", profile.LastName);
      fromdata.append("DateOfBirth", profile.DateOfBirth);
      fromdata.append("EmployeeId", profile.EmployeeId);
      fromdata.append("OrgId", profile.OrgId);
      fromdata.append("Gender", profile.Gender);
      fromdata.append("birthPlace", profile.birthPlace);
      fromdata.append("Maritalstatus", profile.Maritalstatus);
      fromdata.append("PhotoPath", profile.PhotoPath);
      fromdata.append("Qualification", profile.qualification);
      fromdata.append("SkillSet", JSON.stringify(profile.skillSet));
      setBtnLoader(true);
      multipartPostCallWithErrorResponse(ApiConfig.EDIT_PERSONALDATA, fromdata)
        .then((res) => {
          setBtnLoader(false);
          if (res.json.Success === true) {
            ToastMsg("success", res.json.Message);
            handleClose();
            getProfileApi();
          } else {
            console.log("error response,", res);
            ToastMsg("error", res?.json?.Message);
          }
        })
        .catch((err) => {
          setBtnLoader(false);
          console.log("error response,", err);
        });
    }
    setValidated(true);
  };

  const [btnLoader, setBtnLoader] = useState(false);
  const editContactApi = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      setBtnLoader(true);
      PostCallWithErrorResponse(
        ApiConfig.EDIT_EMPLOYEDATA,
        JSON.stringify(cotactEdit)
      ).then((res) => {
        setBtnLoader(false);
        if (res.json.Success) {
          ToastMsg("success", res.json.Message);
          handleClose1();
          getProfileApi();
        }
      });
    }
    setValidation(true);
  };

  const editPreffessianalDetails = () => {
    Promise.all([
      simpleGetCallWithToken(ApiConfig.GETDEPARTMENT_LIST),
      simpleGetCallWithToken(ApiConfig.DESIGNATION_NAME),
      // postWithAuthCallWithErrorResponse(ApiConfig.TEAM_HEAD_lIST),
      simpleGetCallWithToken(ApiConfig.GET_OWNER_NAMES_MODEL_OPTIONS),
      simpleGetCallWithToken(ApiConfig.GET_LOCATION),
      simpleGetCallWithToken(ApiConfig.GET_SHIFT_LIST),
    ])
      .then(([responses1, response2, response3, response5, response6]) => {
        setDepartmentlist(responses1.Data);
        setEmployeeattlist(response3?.Data);
        setDesignationsname(response2.Data);
        setLocation(response5.Data);
        setShift(response6.Data);

        setOpenPrefessionalDetails(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const submitEditProfessionalDetails = (e) => {
    console.log(professionalDtls);

    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      putWithAuthCall(
        ApiConfig.EDIT_EMP_PROFESSIONAL_DTLS,
        JSON.stringify(professionalDtls)
      )
        .then((res) => {
          if (res.Success) {
            console.log(res);
            ToastMsg("success", "Prefessional Details Update Successfully");

            getProfileApi();
          } else {
            ToastMsg("error", "Something Went Wrong Try Again");
          }

          setOpenPrefessionalDetails(false);
        })
        .catch((err) => {
          ToastMsg("error", "Something Went Wrong Try Again");
        });
    }
    setProfessionalDtlsValidation(true);
  };

  useEffect(() => {
    if (id) {
      simpleGetCallWithErrorResponse(ApiConfig.USER_PROFILE_WORK_DETAILS + id)
        .then((res) => {
          console.log("work datils >>>>>>>>>>>>>", res);
          setWorkDetails(res.Data);
        })
        .catch((err) => {
          console.log("error work details >>>>>>>>>>>>>>>>>>>", err);
        });
    } else {
      simpleGetCallWithErrorResponse(ApiConfig.GET_PROFILE_WORK_DETAILS)
        .then((res) => {
          console.log("work datils >>>>>>>>>>>>>", res);
          setWorkDetails(res.Data);
        })
        .catch((err) => {
          console.log("error work details >>>>>>>>>>>>>>>>>>>", err);
        });
    }
  }, []);
  return (
    <>
      {loading && <BackDropLoader />}
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="row d-flex justify-content-around">
          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="dashboard-heading">
              <h3 className="mt-2">
                Hello, {profileData.FirstName} {profileData.LastName}{" "}
              </h3>
            </div>
          </div>
        </div>

        <div className="row d-flex justify-content-around">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-4">
                <div className="user-designation-details">
                  <div className="user-img-box text-center">
                    <img
                      style={{ width: "150px", height: "150px" }}
                      // src={
                      //   profileData.PhotoPath
                      //     ? member_img
                      //     : profileData.PhotoPath.length
                      //     ? ApiConfig.BASE_URL_FOR_IMAGES + profile.PhotoPath
                      //     : profileData.PhotoPath &&
                      //       URL.createObjectURL(profileData.PhotoPath)
                      // }

                      src={
                        !profileData.PhotoPath
                          ? member_img
                          : profileData.PhotoPath.length
                          ? ApiConfig.BASE_URL_FOR_IMAGES +
                            profileData.PhotoPath
                          : profileData.PhotoPath &&
                            URL.createObjectURL(profileData.PhotoPath)
                      }
                      alt=""
                      className="mt-3 mb-3 "
                    />
                    <p className="mb-3">
                      {profileData.FirstName} {profileData.LastName}{" "}
                    </p>
                    <p className="mb-3">{profileData.DesignationName}</p>
                    <p className="mb-3">
                      {" "}
                      {profileData.City} , {profileData.Country}{" "}
                    </p>
                    <p className="mb-4">{profileData.WorkEmail}</p>
                  </div>
                  <div className="lineSeperate2"></div>

                  <div className="professional-details">
                    <div className="professional-heading-main">
                      {/* <div style={{
                     al:"flex-start"
                    }}> */}
                      <h3>Professional Details </h3>
                      {/* </div> */}
                      <img
                        src={optionIcon}
                        onClick={() => {
                          editPreffessianalDetails();
                          // getProfileApi()
                          //handleShow()
                        }}
                        className="pointer"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="user-details mt-4">
                    <label htmlFor="">Designation</label>
                    <p className="mb-3">
                      {profileData.DesignationName
                        ? profileData.DesignationName
                        : ""}
                    </p>
                    <label htmlFor="">Location</label>
                    <p className="mb-3">
                      {profileData.LocationName ? profileData.LocationName : ""}
                    </p>
                    <label htmlFor="">Department</label>
                    <p className="mb-3">
                      {profileData.DepartmentName
                        ? profileData.DepartmentName
                        : ""}
                    </p>
                    <label htmlFor="">Shift</label>
                    <p className="mb-3">
                      {profileData.ShiftName ? profileData.ShiftName : ""}
                    </p>
                    {/* <label htmlFor="">Grade</label>
                      <p className="mb-3">
                        {profileData.Grade ? profileData.Grade : ""}
                      </p> */}
                    <label htmlFor="">Nature of Post </label>
                    <p className="mb-3">
                      {" "}
                      {profileData.NatureOfPost ? profileData.NatureOfPost : ""}
                    </p>
                    <label htmlFor="">Date of Joining</label>
                    <p className="mb-3">
                      {profileData.DateOfJoining
                        ? getDateddmmyyyy(profileData.DateOfJoining)
                        : ""}
                    </p>
                    <label htmlFor="">Reporting To</label>
                    <p className="mb-3">
                      {profileData.ReportingToName
                        ? profileData.ReportingToName
                        : ""}
                    </p>
                    <label htmlFor="">Employee ID</label>
                    <p className="mb-3">
                      {profileData.EmployeeCode ? profileData.EmployeeCode : ""}
                    </p>
                    <p></p>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-12">
                    <div className="Work-details">
                      <div className="personal-heading-main">
                        <h3>Work Details</h3>
                        {/* {permissions &&
                          permissions["Edit Professional Details"] && (
                            <img
                              src={optionIcon}
                              onClick={() => {
                                getProfileApi();
                                handleShow();
                              }}
                              className="pointer"
                              alt=""
                            />
                          )} */}
                      </div>
                      <div className="lineSeperate2"></div>
                      <div className="row p-0">
                        <div className="col-md-6 mt-2">
                          <label htmlFor="">Team Name</label>
                          <p className="mb-3">
                            {workDetails?.TeamName ? workDetails?.TeamName : ""}
                          </p>
                        </div>
                        <div className="col-md-6 mt-2">
                          <label htmlFor="">Projects Name</label>
                          <p className="mb-3"></p>
                        </div>
                        <div className="col-md-6 mt-2">
                          <label htmlFor="">Current Task</label>
                          <p className="mb-3"></p>
                        </div>
                        <div className="col-md-6  mt-2">
                          <label htmlFor="">Shift</label>
                          <p className="mb-3"></p>
                        </div>
                        <div className="col-md-6  mt-2">
                          <label htmlFor=""> Punch-in time</label>
                          <p className="mb-3"></p>
                        </div>
                        <div className="col-md-6  mt-2">
                          <label htmlFor="">Expected Punch Out</label>
                          <p className="mb-3"></p>
                        </div>
                        <div className="col-md-6  mt-2">
                          <label htmlFor="">Status</label>
                          <p className="mb-3"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-3">
                    <div className="personal-details">
                      <div className="personal-heading-main">
                        <h3>Personal Details</h3>
                        {permissions &&
                          permissions["Edit Professional Details"] && (
                            <img
                              src={optionIcon}
                              onClick={() => {
                                getProfileApi();
                                handleShow();
                              }}
                              className="pointer"
                              alt=""
                            />
                          )}
                      </div>
                      <div className="lineSeperate2"></div>
                      <div className="row p-0">
                        <div className="col-md-4 mt-2">
                          <label htmlFor="">First Name</label>
                          <p className="mb-3">
                            {profileData.FirstName ? profileData.FirstName : ""}
                          </p>
                        </div>
                        <div className="col-md-4 mt-2">
                          <label htmlFor="">Middle Name</label>
                          <p className="mb-3">
                            {profileData.MiddleName
                              ? profileData.MiddleName
                              : ""}
                          </p>
                        </div>
                        <div className="col-md-4 mt-2">
                          <label htmlFor="">Last Name</label>
                          <p className="mb-3">
                            {" "}
                            {profileData.LastName ? profileData.LastName : ""}
                          </p>
                        </div>
                        <div className="col-md-4 mt-2">
                          <label htmlFor="">Father's Name</label>
                          <p className="mb-3">
                            {" "}
                            {profileData.FatherName
                              ? profileData.FatherName
                              : ""}
                          </p>
                        </div>
                        <div className="col-md-4 mt-2">
                          <label htmlFor="">Mother's Name</label>
                          <p className="mb-3">
                            {profileData.MotherName
                              ? profileData.MotherName
                              : ""}
                          </p>
                        </div>
                        <div className="col-md-4 mt-2">
                          <label htmlFor="">Blood Group</label>
                          <p className="mb-3">
                            {" "}
                            {profileData.BloodGroup
                              ? profileData.BloodGroup
                              : ""}
                          </p>
                        </div>
                        <div className="col-md-4 mt-2">
                          <label htmlFor="">Nationality</label>
                          <p className="mb-3">
                            {" "}
                            {profileData.Nationality
                              ? profileData.Nationality
                              : ""}
                          </p>
                        </div>
                        {/* <div className="col-md-4 mt-2">
                          <label htmlFor="">Middle Name</label>
                          <p className="mb-3">
                            {profileData.MiddleName
                              ? profileData.MiddleName
                              : ""}
                          </p>
                        </div> */}
                        <div className="col-md-4 mt-2">
                          <label htmlFor="">Identification Mark</label>
                          <p className="mb-3">
                            {profileData.IdentificationMark
                              ? profileData.IdentificationMark
                              : ""}
                          </p>
                        </div>
                        <div className="col-md-4 mt-2">
                          <label htmlFor="">Religion</label>
                          <p className="mb-3">
                            {profileData.Religion ? profileData.Religion : ""}
                          </p>
                        </div>

                        <div className="col-md-4">
                          <label htmlFor="">Date of Birth</label>
                          <p className="mb-3">
                            {profileData.DOB
                              ? getDateddmmyyyy(profileData.DOB)
                              : ""}
                          </p>
                        </div>
                        {/* <div className="col-md-4">
                          <label htmlFor="">Employee IDd</label>
                          <p className="mb-3">
                            {profileData.EmployeeCode
                              ? profileData.EmployeeCode
                              : ""}
                          </p>
                        </div> */}
                        <div className="col-md-4">
                          <label htmlFor="">Gender</label>
                          <p className="mb-3">
                            {profileData.Gender ? profileData.Gender : ""}
                          </p>
                        </div>
                        {/* <div className="col-md-4">
                          <label htmlFor="">Birth Place</label>
                          <p className="mb-3">
                            {profileData.BirthPlace
                              ? profileData.BirthPlace
                              : ""}
                          </p>
                        </div> */}
                        <div className="col-md-4">
                          <label htmlFor="">Marital Status</label>
                          <p className="mb-3">
                            {profileData.MaritalStatus
                              ? profileData.MaritalStatus
                              : ""}
                          </p>
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="">Qualification</label>
                          <p className="mb-3">
                            {profileData?.Qualification?.length > 0
                              ? profileData.Qualification
                              : "-"}
                          </p>
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="">Skill Set</label>
                          <p>{profileData?.SkillSet}</p>
                          {/* {
                            profileData?.SkillSet&&JSON.parse(profileData?.SkillSet)?.map((i,index)=>{
                              return <p className="mb-3">
                              {index+1} : {i}
                            </p>
                            })
                          }
                           */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-2">
                <div className="contact-details">
                  <div className="contact-heading-main">
                    <h3>Contact Details</h3>
                    {permissions && permissions["Edit Contact Details"] && (
                      <img
                        src={optionIcon}
                        onClick={() => {
                          getProfileApi();
                          handleShow1();
                        }}
                        className="pointer"
                        alt=""
                      />
                    )}
                  </div>
                  <div className="lineSeperate2"></div>
                  <div className="row p-0">
                    <div className="col-md-6 mt-2">
                      <label htmlFor="">Work Email</label>
                      <p className="mb-3">{profileData?.WorkEmail} </p>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="">Address</label>
                      <p
                        className="mb-3 "
                        style={{
                          maxWidth: "100%",
                          overflowWrap: "break-word",
                        }}
                      >
                        {profileData?.Address}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="">Other Email</label>
                      <p className="mb-3">{profileData?.OtherEmail}</p>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="">City</label>
                      <p className="mb-3">{profileData?.City}</p>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="">Mobile Number</label>
                      <p className="mb-3">{profileData?.MobileNo}</p>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="">State / Province</label>
                      <p className="mb-3">{profileData?.State}</p>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="">Work Telephone</label>
                      <p className="mb-3">{profileData?.WorkTelephone}</p>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="">Zip / Postal Code</label>
                      <p className="mb-3">{profileData?.Pincode}</p>
                    </div>
                    {/* <div className="col-md-6">
                        <label htmlFor="">Extention</label>
                        <p className="mb-3">{profileData.Extension}</p>
                      </div> */}
                    <div className="col-md-6">
                      <label htmlFor="">Permanent Address</label>
                      <p
                        className="mb-3"
                        style={{
                          maxWidth: "100%",
                          overflowWrap: "break-word",
                        }}
                      >
                        {profileData?.PermanentAddress}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="">Emergency Contact</label>
                      <p className="mb-3">{profileData?.EmergencyContact}</p>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="">Country</label>
                      <p className="mb-3">{profileData?.Country}</p>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="">Emergency contact Person</label>
                      <p className="mb-3">{profileData?.EmergencyPerson}</p>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="">Home Telephone</label>
                      <p className="mb-3">{profileData?.HomeTelephone} </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Edit Personal Details Modal Start */}

        <Modal
          size="xl"
          show={show}
          onHide={() => {
            handleClose();
            setValidated(false);
          }}
          aria-labelledby="modal-90w"
          className="add-task-modal-main"
          centered
        >
          <Modal.Header closeButton className="add-task-modal-header ">
            <Modal.Title id="example-modal-sizes-title-lg">
              Edit Personal details
            </Modal.Title>
          </Modal.Header>
          <div className="lineSeperate"></div>
          <Form noValidate validated={validated} onSubmit={updatePersonalApi}>
            <Modal.Body>
              <div className="personalDetailsModal">
                <div className="row add-task-modal-inputs">
                  <div className="col-lg-2">
                    <div className="personalDetails-img-upload text-center">
                      <img
                        src={
                          !profile.PhotoPath
                            ? profilePicBG
                            : profile.PhotoPath.length
                            ? ApiConfig.BASE_URL_FOR_IMAGES + profile.PhotoPath
                            : profile.PhotoPath &&
                              URL.createObjectURL(profile.PhotoPath)
                        }
                        alt=""
                        className="porfile_image"
                        style={{ width: "80%", height: "60%" }}
                      />
                      <input
                        type="file"
                        className="custom-file-input mt-2"
                        onChange={(e) => {
                          setProfile({
                            ...profile,
                            PhotoPath: e.target.files[0],
                          });
                        }}
                        accept="image/*"
                        required={!profile.PhotoPath ? true : false}
                        style={{ width: "100px" }}
                      />
                      {/* <div className="invalid-feedback">Upload Profile Picture</div> */}
                      {!profile.PhotoPath && (
                        <div className="invalid-feedback">
                          Upload Profile Picture
                        </div>
                      )}
                    </div>
                    {/* <div class="cx-form-group">
                    <label for="cx-pro-cam" className="cx-pro-cam ">
                      <img
                        class="cx-pdefault"
                        src={
                          !profile.PhotoPath
                            ? profilePicBG
                            : profile.PhotoPath.length
                            ? ApiConfig.BASE_URL_FOR_IMAGES + profile.PhotoPath
                            : profile.PhotoPath &&
                              URL.createObjectURL(profile.PhotoPath)
                        }
                      />
                      <img class="cx-camera" src={fileUpload} />
                    </label>
                    <input
                      type="file"
                      className="custom-file-input mt-2"
                      onChange={(e) => {
                        setProfile({
                          ...profile,
                          PhotoPath: e.target.files[0],
                        });
                      }}
                      required
                    />
                  </div> */}
                  </div>
                  <div className="col-lg-10">
                    <div className="row p-0">
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-2 text-grey">
                          First Name
                        </label>
                        <input
                          type="text"
                          className="form-control tasKCategory1 mb-2"
                          onChange={(e) => {
                            setProfile({
                              ...profile,
                              firstName: e.target.value,
                            });
                          }}
                          value={profile.firstName}
                          required
                        />
                        <div className="invalid-feedback">Enter First Name</div>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-2 text-grey">
                          Middle Name
                        </label>
                        <input
                          type="text"
                          className="form-control tasKCategory1 mb-2"
                          onChange={(e) => {
                            setProfile((prev) => ({
                              ...prev,
                              MiddleName: e.target.value,
                            }));
                          }}
                          value={profile.MiddleName}
                        />
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-2 text-grey">
                          Last Name
                        </label>
                        <input
                          type="text"
                          className="form-control tasKCategory1 mb-2"
                          onChange={(e) => {
                            setProfile({
                              ...profile,
                              LastName: e.target.value,
                            });
                          }}
                          value={profile.LastName}
                          required
                        />
                        <div className="invalid-feedback">Enter last Name</div>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-2 text-grey">
                          Father's Name
                        </label>
                        <input
                          type="text"
                          className="form-control tasKCategory1 mb-2"
                          onChange={(e) => {
                            setProfile({
                              ...profile,
                              FatherName: e.target.value,
                            });
                          }}
                          value={profile.FatherName}
                          required
                        />
                        <div className="invalid-feedback">
                          Please Enter Father Name
                        </div>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-2 text-grey">
                          Mother's Name
                        </label>
                        <input
                          type="text"
                          className="form-control tasKCategory1 mb-2"
                          onChange={(e) => {
                            setProfile({
                              ...profile,
                              MotherName: e.target.value,
                            });
                          }}
                          value={profile.MotherName}
                          required
                        />
                        <div className="invalid-feedback">
                          Please Enter Mother Name
                        </div>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-2 text-grey">
                          Date of Birth
                        </label>
                        <input
                          type="date"
                          className="form-control tasKCategory1 mb-2"
                          onChange={(e) => {
                            setProfile({
                              ...profile,
                              DateOfBirth: e.target.value,
                            });
                          }}
                          value={profile.DateOfBirth}
                          required
                        />
                        <div className="invalid-feedback">
                          Enter Date Of Birth
                        </div>
                      </div>
                      {/* <div className="col-md-4">
                        <label htmlFor="" className="mb-2 text-grey">
                          Birth Place
                        </label>
                        <input
                          type="text"
                          className="form-control tasKCategory1 mb-2"
                          onChange={(e) => {
                            setProfile({
                              ...profile,
                              birthPlace: e.target.value,
                            });
                          }}
                          value={profile.birthPlace}
                          required
                        />
                        <div className="invalid-feedback">
                          Enter birth Place{" "}
                        </div>
                      </div> */}
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-2 text-grey">
                          Gender
                        </label>
                        <select
                          className="form-select tasKCategory1 mb-2"
                          aria-label="Default Select Priority"
                          onChange={(e) => {
                            setProfile({
                              ...profile,
                              Gender: e.target.value,
                            });
                          }}
                          value={profile.Gender ? profile.Gender : ""}
                          required
                        >
                          <option value="">Select Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                        <div className="invalid-feedback">
                          Please Select Gender
                        </div>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-2 text-grey">
                          Blood Group
                        </label>
                        <select
                          className="form-select tasKCategory1 mb-2"
                          aria-label="Default Select Priority"
                          onChange={(e) => {
                            setProfile({
                              ...profile,
                              BloodGroup: e.target.value,
                            });
                          }}
                          value={profile.BloodGroup ? profile.BloodGroup : ""}
                        >
                          <option value="">Slect Blood Group</option>
                          <option value="A">A+</option>
                          <option value="B+">B+</option>
                          <option value="AB+">AB+</option>
                          <option value="AB-">AB-</option>
                          <option value="B-">B-</option>
                          <option value="O+">O+</option>
                          <option value="O-">O-</option>
                        </select>
                        {/* <div className="invalid-feedback">
                        Select Blood Group{" "}
                      </div> */}
                      </div>

                      <div className="col-md-4">
                        <label htmlFor="" className="mb-2 text-grey">
                          Identification Mark
                        </label>
                        <input
                          type="text"
                          className="form-control tasKCategory1 mb-2"
                          onChange={(e) => {
                            setProfile({
                              ...profile,
                              IdentificationMark: e.target.value,
                            });
                          }}
                          value={
                            profile.IdentificationMark
                              ? profile.IdentificationMark
                              : ""
                          }
                        />
                        {/* <div className="invalid-feedback">
                        Please Enter Identification Mark
                      </div> */}
                      </div>

                      {/* <div className="col-md-4">
                      <label htmlFor="" className="mb-2 text-grey">
                        Employee ID
                      </label>
                      <input
                        type="text"
                        className="form-control tasKCategory1 mb-2"
                        onChange={(e) => {
                          setProfile({
                            ...profile,
                            EmployeeId: e.target.value,
                          });
                        }}
                        disabled={profile.EmployeeId ? true : false}
                        value={profile.EmployeeId}
                        required
                      />
                      <div className="invalid-feedback">
                        Please Enter EmployeeId
                      </div>
                    </div> */}

                      <div className="col-md-4">
                        <label htmlFor="" className="mb-2 text-grey">
                          Marital Status
                        </label>
                        <select
                          className="form-select tasKCategory1 mb-2"
                          aria-label="Default Select Priority"
                          onChange={(e) => {
                            setProfile({
                              ...profile,
                              Maritalstatus: e.target.value,
                            });
                          }}
                          value={profile.Maritalstatus}
                          required
                        >
                          <option value="">Select Marital Status</option>
                          <option value="Single">Single</option>
                          <option value="Married">Married</option>
                        </select>
                        <div className="invalid-feedback">
                          Please Enter Marriage Status
                        </div>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-2 text-grey">
                          Nationality
                        </label>
                        <input
                          type="text"
                          className="form-control tasKCategory1 mb-2"
                          onChange={(e) => {
                            setProfile({
                              ...profile,
                              Nationality: e.target.value,
                            });
                          }}
                          value={profile.Nationality ? profile.Nationality : ""}
                          required
                        />
                        <div className="invalid-feedback">
                          Please Enter Nationality{" "}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-2 text-grey">
                          Religion
                        </label>
                        <input
                          type="text"
                          className="form-control tasKCategory1 mb-2"
                          onChange={(e) => {
                            setProfile({
                              ...profile,
                              Religion: e.target.value,
                            });
                          }}
                          value={profile.Religion ? profile.Religion : ""}
                          required
                        />
                        <div className="invalid-feedback">
                          Please Enter Religion{" "}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-2 text-grey">
                          Qualification
                        </label>
                        <input
                          type="text"
                          className="form-control tasKCategory1 mb-2"
                          onChange={(e) => {
                            setProfile({
                              ...profile,
                              qualification: e.target.value,
                            });
                          }}
                          value={
                            profile.qualification ? profile.qualification : ""
                          }
                          required
                        />
                        <div className="invalid-feedback">
                          Please Enter qualification{" "}
                        </div>
                      </div>

                      <div className="col-md-4 ">
                        <label htmlFor="" className="mb-2">
                          Skill Set
                        </label>

                        <div className="d-flex">
                          <input
                            type="text"
                            className="date-label-input tasKCategory1"
                            style={{
                              fontSize: "14px",
                              height: "38px",
                              border: "1px solid #ced4da",
                            }}
                            onChange={(e) => setSkill(e.target.value)}
                            name="skillSet"
                            value={skill}
                          />
                          <button
                            className="nextBtn"
                            type="button"
                            style={{
                              width: "50px",
                              color: "white",
                              backgroundColor: "#53b7e8",
                              border: "2px solid #53b7e8",
                              marginLeft: "3px",
                              borderRadius: "10px",
                            }}
                            onClick={() => {
                              if (skill.length > 0) {
                                setProfile((prev) => ({
                                  ...prev,
                                  skillSet: [...profile?.skillSet, skill],
                                }));
                                setSkill("");
                              }
                            }}
                          >
                            +
                          </button>
                        </div>

                        <div className="row">
                          {profile.skillSet?.map((skill, index) => {
                            return (
                              <div
                                className="col-md-3 gap-2 d-flex justify-content-between"
                                style={{
                                  width: "150px",
                                  border: "1px solid grey",
                                  borderRadius: "5px",
                                  marginLeft: "13px",
                                  marginTop: "5px",
                                }}
                              >
                                {" "}
                                <p key={index}>{skill}</p>
                                <span
                                  style={{ cursor: "pointer" }}
                                  className="nxt"
                                  onClick={() => {
                                    let removeSkills = profile.skillSet.filter(
                                      (_, indx) => {
                                        return index != indx;
                                      }
                                    );

                                    setProfile((prev) => ({
                                      ...prev,
                                      skillSet: removeSkills,
                                    }));
                                  }}
                                >
                                  x
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <div className="lineSeperate"></div>
            <div className="d-flex justify-content-end align-items-center mainBtnsSub">
              <button
                className="btn-width cancelBtn mx-3"
                type="button"
                onClick={() => {
                  handleClose();
                  setValidated(false);
                }}
              >
                Cancel
              </button>
              <button className="btn-width saveBtn">
                {btnLoader ? <ButtonLoader /> : "Update"}
              </button>
            </div>
          </Form>
        </Modal>

        {/* Personal Details Modal End */}

        {/* Contact Details Modal Start */}

        <Modal
          size="xl"
          show={show1}
          onHide={() => {
            setValidated(false);
            handleClose1();
          }}
          aria-labelledby="modal-90w"
          className="add-task-modal-main"
          centered
        >
          <Modal.Header closeButton className="add-task-modal-header ">
            <Modal.Title id="example-modal-sizes-title-lg">
              Edit Contact details
            </Modal.Title>
          </Modal.Header>
          <div className="lineSeperate"></div>
          <Form noValidate validated={validation} onSubmit={editContactApi}>
            <Modal.Body>
              <div className="personalDetailsModal">
                <div className="row add-task-modal-inputs">
                  <div className="col-md-12">
                    <div className="row p-0">
                      <div className="col-md-3 mb-2">
                        <label htmlFor="" className="mb-2 text-grey">
                          Work Email
                        </label>
                        <input
                          type="email"
                          className="form-control tasKCategory1 mb-2"
                          onChange={(e) => {
                            setCotactEdit({
                              ...cotactEdit,
                              WorkEmail: e.target.value,
                            });
                          }}
                          value={
                            cotactEdit.WorkEmail ? cotactEdit.WorkEmail : ""
                          }
                          required
                        />
                        <div className="invalid-feedback">
                          Enter work Official address
                        </div>
                      </div>
                      <div className="col-md-3 mb-2">
                        <label htmlFor="" className="mb-2 text-grey">
                          Other Email
                        </label>
                        <input
                          type="email"
                          className="form-control tasKCategory1 mb-2"
                          onChange={(e) => {
                            setCotactEdit({
                              ...cotactEdit,
                              OtherEmail: e.target.value,
                            });
                          }}
                          value={
                            cotactEdit.OtherEmail ? cotactEdit.OtherEmail : ""
                          }
                        />
                        {/* <div className="invalid-feedback">
                        Enter your Personal Email
                      </div> */}
                      </div>
                      <div className="col-md-3 mb-2">
                        <label htmlFor="" className="mb-2 text-grey">
                          Mobile Phone
                        </label>
                        <input
                          type="text"
                          className="form-control tasKCategory1 mb-2"
                          maxLength={10}
                          onChange={(e) => {
                            if (!isNaN(e.target.value)) {
                              setCotactEdit({
                                ...cotactEdit,
                                MobileNo: e.target.value,
                              });
                            }
                          }}
                          value={cotactEdit.MobileNo ? cotactEdit.MobileNo : ""}
                          required
                        />
                        <div className="invalid-feedback">
                          Enter Mobile Number
                        </div>
                      </div>
                      <div className="col-md-3 mb-2">
                        <label htmlFor="" className="mb-2 text-grey">
                          Work Telephone
                        </label>
                        <input
                          type="text"
                          className="form-control tasKCategory1 mb-2"
                          maxLength={10}
                          onChange={(e) => {
                            if (!isNaN(e.target.value)) {
                              setCotactEdit({
                                ...cotactEdit,
                                WorkTelephone: e.target.value,
                              });
                            }
                          }}
                          value={
                            cotactEdit.WorkTelephone
                              ? cotactEdit.WorkTelephone
                              : ""
                          }
                        />
                        {/* <div className="invalid-feedback">
                        Enter Mobile Number
                      </div> */}
                      </div>
                      {/* <div className="col-md-3 mb-2">
                      <label htmlFor="" className="mb-2 text-grey">
                        Country Code
                      </label>
                      <input
                        type="text"
                        className="form-control tasKCategory1 mb-2"
                        onChange={(e) => {
                          setCotactEdit({
                            ...cotactEdit,
                            Extension: e.target.value,
                          });
                        }}
                        value={cotactEdit.Extension}
                        required
                      />
                      <div className="invalid-feedback">
                        Enter Extension Number
                      </div>
                    </div> */}
                      <div className="col-md-3 mb-2">
                        <label htmlFor="" className="mb-2 text-grey">
                          Emergency contact
                        </label>
                        <input
                          type="text"
                          className="form-control tasKCategory1 mb-2"
                          maxLength={10}
                          onChange={(e) => {
                            if (!isNaN(e.target.value)) {
                              setCotactEdit({
                                ...cotactEdit,
                                EmergencyContact: e.target.value,
                              });
                            }
                          }}
                          value={
                            cotactEdit.EmergencyContact
                              ? cotactEdit.EmergencyContact
                              : ""
                          }
                          required
                        />
                        <div className="invalid-feedback">
                          Enter Emergency Contact Number
                        </div>
                      </div>
                      <div className="col-md-3 mb-2">
                        <label htmlFor="" className="mb-2 text-grey">
                          Emergency contact person
                        </label>
                        <input
                          type="text"
                          className="form-control tasKCategory1 mb-2"
                          value={cotactEdit.EmergencyPerson}
                          onChange={(e) => {
                            let letters = /^[A-Za-z]+$/;

                            if (e.target.value.match(letters)) {
                              setCotactEdit({
                                ...cotactEdit,
                                EmergencyPerson: e.target.value,
                              });
                            }
                          }}
                        />
                      </div>
                      <div className="col-md-3 mb-2">
                        <label htmlFor="" className="mb-2 text-grey">
                          Home Telephone
                        </label>
                        <input
                          type="text"
                          className="form-control tasKCategory1 mb-2"
                          onChange={(e) => {
                            if (!isNaN(e.target.value)) {
                              setCotactEdit({
                                ...cotactEdit,
                                HomeTelephone: e.target.value,
                              });
                            }
                          }}
                          value={
                            cotactEdit.HomeTelephone
                              ? cotactEdit.HomeTelephone
                              : ""
                          }
                        />
                        {/* <div className="invalid-feedback">
                        Enter Home Telephone Number
                      </div> */}
                      </div>
                      <div className="col-md-6 mb-2">
                        <label htmlFor="" className="mb-2 text-grey">
                          Current Address
                        </label>
                        <textarea
                          className="form-control tasKCategory1 mb-2"
                          rows="3"
                          onChange={(e) => {
                            setCotactEdit({
                              ...cotactEdit,
                              Address: e.target.value,
                            });
                          }}
                          value={cotactEdit.Address ? cotactEdit.Address : ""}
                          required
                        ></textarea>
                        <div className="invalid-feedback">Enter Address</div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <label htmlFor="" className="mb-2 text-grey">
                          Permanent Address
                        </label>
                        <textarea
                          className="form-control tasKCategory1 mb-2"
                          rows="3"
                          onChange={(e) => {
                            setCotactEdit({
                              ...cotactEdit,
                              PermanentAddress: e.target.value,
                            });
                          }}
                          value={
                            cotactEdit.PermanentAddress
                              ? cotactEdit.PermanentAddress
                              : ""
                          }
                          required
                        ></textarea>
                        <div className="invalid-feedback">
                          Enter Permanent Address
                        </div>
                      </div>
                      <div className="col-md-3 mb-2">
                        <label htmlFor="" className="mb-2 text-grey">
                          Country
                        </label>
                        <input
                          type="text"
                          className="form-control tasKCategory1"
                          onChange={(e) => {
                            setCotactEdit({
                              ...cotactEdit,
                              Country: e.target.value,
                            });
                          }}
                          value={cotactEdit.Country ? cotactEdit.Country : ""}
                          required
                        />
                        <div className="invalid-feedback">
                          Enter Country Name
                        </div>
                      </div>
                      <div className="col-md-3 mb-2">
                        <label htmlFor="" className="mb-2 text-grey">
                          City
                        </label>
                        <input
                          type="text"
                          className="form-control tasKCategory1 mb-2"
                          onChange={(e) => {
                            setCotactEdit({
                              ...cotactEdit,
                              City: e.target.value,
                            });
                          }}
                          value={cotactEdit.City ? cotactEdit.City : ""}
                          required
                        />
                        <div className="invalid-feedback">Enter City Name</div>
                      </div>
                      <div className="col-md-3 mb-2">
                        <label htmlFor="" className="mb-2 text-grey">
                          State / Province
                        </label>
                        <input
                          type="text"
                          className="form-control tasKCategory1 mb-2"
                          onChange={(e) => {
                            setCotactEdit({
                              ...cotactEdit,
                              State: e.target.value,
                            });
                          }}
                          value={cotactEdit.State ? cotactEdit.State : ""}
                          required
                        />
                        <div className="invalid-feedback">Enter State Name</div>
                      </div>
                      <div className="col-md-3 mb-2">
                        <label htmlFor="" className="mb-2 text-grey">
                          Zip / Postal Code
                        </label>
                        <input
                          type="text"
                          className="form-control tasKCategory1 mb-2"
                          onChange={(e) => {
                            setCotactEdit({
                              ...cotactEdit,
                              Pincode: e.target.value,
                            });
                          }}
                          value={cotactEdit.Pincode ? cotactEdit.Pincode : ""}
                          required
                        />
                        <div className="invalid-feedback">Enter State Name</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <div className="lineSeperate"></div>
            <div className="d-flex justify-content-end align-items-center mainBtnsSub">
              <button
                className="btn-width cancelBtn mx-3"
                type="button"
                onClick={handleClose1}
              >
                Cancel
              </button>
              <button className="btn-width saveBtn">
                {btnLoader ? <ButtonLoader /> : "Update"}
              </button>
            </div>
          </Form>
        </Modal>

        <Modal
          size="xl"
          show={openProfessionalDetails}
          onHide={() => {
            setOpenPrefessionalDetails(false);
            setOpenPrefessionalDetails(false);
            setProfessionalDtlsValidation(false);
          }}
          aria-labelledby="modal-90w"
          className="add-task-modal-main"
          centered
        >
          <Modal.Header closeButton className="add-task-modal-header ">
            <Modal.Title id="example-modal-sizes-title-lg">
              Edit Perfessional details
            </Modal.Title>
          </Modal.Header>
          <div className="lineSeperate"></div>
          <Form
            noValidate
            validated={professionalDtlsValidation}
            onSubmit={submitEditProfessionalDetails}
          >
            <Modal.Body>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="modalProp mb-2">
                    <label
                      htmlFor=""
                      className="enter-role-text mt-2"
                      style={{ fontSize: "14px" }}
                    >
                      Department
                    </label>
                    <select
                      class="form-select tasKCategory1 select-drop-icon"
                      aria-label="Default select example"
                      onChange={(e) =>
                        handleProfessionalDtls(
                          "DepartmentId",
                          Number(e.target.value)
                        )
                      }
                      value={professionalDtls?.DepartmentId}
                      name="DepartmentId"
                      required
                    >
                      <option value="">Select Department</option>
                      {departmentlist &&
                        departmentlist.map((dept, index) => {
                          return (
                            <option value={dept.Id}>
                              {dept.DepartmentName}
                            </option>
                          );
                        })}
                    </select>
                    <div className="invalid-feedback">Select Department</div>
                  </div>
                  <div className="modalProp mb-2">
                    <label
                      htmlFor=""
                      className="enter-role-text mt-2"
                      style={{ fontSize: "14px" }}
                    >
                      Designation
                    </label>
                    <select
                      class="form-select tasKCategory1 select-drop-icon"
                      aria-label="Default select example"
                      onChange={(e) =>
                        handleProfessionalDtls(
                          "DesignationId",
                          Number(e.target.value)
                        )
                      }
                      name="DesignationId"
                      value={professionalDtls?.DesignationId}
                      required
                    >
                      <option value="">Select Designation</option>
                      {designationsname &&
                        designationsname.map((desi, index) => {
                          return (
                            <option value={desi.DesignationId}>
                              {desi.Name}
                            </option>
                          );
                        })}
                    </select>
                    <div className="invalid-feedback">Select Designation</div>
                  </div>
                  <div className="modalProp mb-2">
                    <label
                      htmlFor=""
                      className="enter-role-text mt-2"
                      style={{ fontSize: "14px" }}
                    >
                      Location
                    </label>
                    <select
                      class="form-select tasKCategory1 select-drop-icon"
                      aria-label="Default select example"
                      onChange={(e) =>
                        handleProfessionalDtls(
                          "LocationId",
                          Number(e.target.value)
                        )
                      }
                      name="LocationId"
                      value={professionalDtls?.LocationId}
                      required
                    >
                      <option value="">Select Location</option>

                      {location &&
                        location.map((loc) => {
                          return (
                            <option value={loc.LocationId}>
                              {loc.LocationName}
                            </option>
                          );
                        })}
                    </select>
                    <div className="invalid-feedback">Select Location</div>
                  </div>
                  <div className="modalProp mb-2">
                    <label
                      htmlFor=""
                      className="enter-role-text mt-2"
                      style={{ fontSize: "14px" }}
                    >
                      Shift
                    </label>
                    <select
                      class="form-select tasKCategory1 select-drop-icon"
                      aria-label="Default select example"
                      onChange={(e) =>
                        handleProfessionalDtls(
                          "ShiftId",
                          Number(e.target.value)
                        )
                      }
                      name="ShiftId"
                      value={professionalDtls?.ShiftId}
                      required
                    >
                      <option value="">Select Shift</option>
                      {shift &&
                        shift.map((shif) => {
                          return (
                            <option value={shif.ShiftId}>
                              {shif.ShiftName}
                            </option>
                          );
                        })}
                    </select>
                    <div className="invalid-feedback">Select Shift</div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="modalProp mb-2">
                    <label
                      htmlFor=""
                      className="enter-role-text mt-2"
                      style={{ fontSize: "14px" }}
                    >
                      Nature of post
                    </label>

                    <>
                      <select
                        class="form-select tasKCategory1 select-drop-icon"
                        aria-label="Default select example"
                        onChange={(e) =>
                          handleProfessionalDtls("NatureOfPost", e.target.value)
                        }
                        name="NatureOfPost"
                        value={professionalDtls?.NatureOfPost}
                        required
                      >
                        <option value="">Select Nature of post</option>
                        <option value="Contract"> contract </option>
                        <option value="Internship">Internship</option>
                        <option value="Permanent"> Permanent </option>
                      </select>
                    </>

                    <div className="invalid-feedback">Select NatureOfPost</div>
                  </div>
                  <div className="modalProp mb-2">
                    <label
                      htmlFor=""
                      className="enter-role-text mt-2"
                      style={{ fontSize: "14px" }}
                    >
                      Reporting Person
                    </label>
                    <select
                      class="form-select tasKCategory1 select-drop-icon"
                      aria-label="Default select example"
                      onChange={(e) =>
                        handleProfessionalDtls(
                          "ReportingTo",
                          Number(e.target.value)
                        )
                      }
                      value={professionalDtls?.ReportingTo}
                      name="DepartmentId"
                      required
                    >
                      <option value="">Select Reporting Person</option>
                      {employeeattlist &&
                        employeeattlist.map((rept, index) => {
                          return (
                            <option value={rept.Id}>{rept.FirstName}</option>
                          );
                        })}
                    </select>
                    <div className="invalid-feedback">
                      Select Reporting Person
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <div className="lineSeperate"></div>
            <div className="d-flex justify-content-end align-items-center mainBtnsSub">
              <button
                className="btn-width cancelBtn mx-3"
                type="button"
                onClick={() => setOpenPrefessionalDetails(false)}
              >
                Cancel
              </button>
              <button className="btn-width saveBtn">
                {btnLoader ? <ButtonLoader /> : "Update"}
              </button>
            </div>
          </Form>
        </Modal>

        {/* Contact Details Modal End */}
      </div>
    </>
  );
};

export default Profile;
